/*
 This file determines which environment we're in, and provides the app with the appropriate config
 */

export const foundryRoutes = (uri) => {
  let result = {
    uri: uri,
    get activePermissions() {
      return uri + '/permissions/v1/activePermissions';
    },
    get allPermissions() {
      return uri + '/permissions/v1/allPermissions';
    },
    get allGroups() {
      return uri + '/permissions/v1/allGroups';
    },
    get editPermissions() {
      return uri + '/permissions/v1/editPermissions';
    },
    get athleteSettings() {
      return uri + '/settings/v1/athleteSettings';
    },
    get grandProxy() {
      return uri + '/graphql';
    },
  };
  return result;
};

export const exchangeTokenRoutes = (uri) => {
  let result = {
    get tokenExchangeUrl() {
      return uri + '/cs/tokenexchange/v1/token_exchange';
    },
  };
  return result;
};

export const defaults = {
  appid: 'nike.cs.obo',
  imageBaseURI: 'http://images2.nike.com/is/image/DPILS/',
  foundry: foundryRoutes('https://foundry.nike.com'),
  searchLink: 'https://omobo.nike.com/order/search',
  tokenExchange: exchangeTokenRoutes('https://services.c4c-csp-test.nikecloud.com.cn'),
  settingsLink: 'https://omobo.nike.com/settings',
  okta: {
    clientId: 'nike.cs.obo',
    issuer: 'https://nike.okta.com/oauth2/ausie6vytqfCFiryw1t7',
    cypressIssuer: 'https://nike.okta.com/oauth2/ausie6vytqfCFiryw1t7',
    redirectUri:
      window.location.origin + process.env.PUBLIC_URL?.toLowerCase() + '/implicit/callback/',
    pkce: true,
    tokenManager: {
      autoRenew: false,
      storageKey: 'nike.cs.obo.ocobo',
    },
    scopes: [
      'openid',
      'email',
      'profile',
      'consumer_conversations:consumerservices.onbehalfof::create:',
    ],
  },
};
export const dev = {
  searchLink: 'http://localhost:3000',
  settingsLink: 'https://omobo-test.nike.com/settings',
};

export const local = {
  foundry: foundryRoutes('http://localhost:9090'),
  searchLink: 'http://localhost:3000',
  // while running locally, for settings link to work, run omobo in 3001 port
  settingsLink: 'http://localhost:3001/settings',
};

export const localCN = {
  foundry: foundryRoutes('http://localhost:9090'),
  searchLink: 'http://localhost:3000/portlets/postpurchase',
  tokenExchange: exchangeTokenRoutes('https://services.c4c-csp-prod.nikecloud.com.cn'),
  // while running locally, for settings link to work, run omobo in 3001 port
  settingsLink: 'http://localhost:3001/portlets/postpurchase/settings',
  imageBaseURI: 'https://images.nike.com.cn/is/image/DPILS/',
  okta: {
    clientId: 'nike.cs.ocobo-cn',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
    cypressIssuer: 'https://nike.okta.com/oauth2/ausie6vytqfCFiryw1t7',
    redirectUri:
      window.location.origin + process.env.PUBLIC_URL?.toLowerCase() + '/implicit/callback/',
    pkce: true,
    tokenManager: {
      autoRenew: true,
      storageKey: 'nike.cs.ocobo-cn',
    },
    scopes: ['openid', 'email', 'profile', 'consumer_profile:l7r.token_exchange::create:'],
  },
};

export const testCN = {
  foundry: foundryRoutes('https://csp.nike.com.cn/foundry'),
  tokenExchange: exchangeTokenRoutes('https://services.c4c-csp-prod.nikecloud.com.cn'),
  searchLink: 'https://csp.nike.com.cn/portlets/postpurchase/order/search',
  settingsLink: 'https://csp.nike.com.cn/portlets/postpurchase/settings',
  imageBaseURI: 'https://images.nike.com.cn/is/image/DPILS/',
  okta: {
    clientId: 'nike.cs.ocobo-cn',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
    cypressIssuer: 'https://nike.okta.com/oauth2/ausie6vytqfCFiryw1t7',
    redirectUri:
      window.location.origin + process.env.PUBLIC_URL?.toLowerCase() + '/implicit/callback/',
    pkce: true,
    tokenManager: {
      autoRenew: true,
      storageKey: 'nike.cs.ocobo-cn',
    },
    scopes: ['openid', 'email', 'profile', 'consumer_profile:l7r.token_exchange::create:'],
  },
};

export const prod = {};

export const prodCN = {
  foundry: foundryRoutes('https://csp.nike.com.cn/foundry'),
  tokenExchange: exchangeTokenRoutes('https://services.c4c-csp-prod.nikecloud.com.cn'),
  searchLink: 'https://csp.nike.com.cn/portlets/postpurchase/order/search',
  settingsLink: 'https://csp.nike.com.cn/portlets/postpurchase/settings',
  imageBaseURI: 'https://images.nike.com.cn/is/image/DPILS/',
  okta: {
    clientId: 'nike.cs.ocobo-cn',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
    cypressIssuer: 'https://nike.okta.com/oauth2/ausie6vytqfCFiryw1t7',
    redirectUri:
      window.location.origin + process.env.PUBLIC_URL?.toLowerCase() + '/implicit/callback/',
    pkce: true,
    tokenManager: {
      autoRenew: true,
      storageKey: 'nike.cs.ocobo-cn',
    },
    scopes: ['openid', 'email', 'profile', 'consumer_profile:l7r.token_exchange::create:'],
  },
};

export const config = (environment) => {
  switch (environment) {
    case 'dev':
      return dev;
    case 'testCN':
      return testCN;
    case 'local':
      return local;
    case 'localCN':
      return localCN;
    case 'prodCN':
      return prodCN;
    default:
      return prod;
  }
};

export default {
  ...defaults,
  ...config(process.env.REACT_APP_ENV),
};
