export const actionTypes = {
  SET_CONSUMER: 'SET_CONSUMER',
  SET_VISITOR_ID: 'SET_VISITOR_ID',
  SET_CONSUMER_TYPE: 'SET_CONSUMER_TYPE',
  SET_CONSUMER_EMAIL: 'SET_CONSUMER_EMAIL',
  SET_CONSUMER_NAME: 'SET_CONSUMER_NAME',
  SET_KANA_FIRST_NAME: 'SET_KANA_FIRST_NAME',
  SET_KANA_LAST_NAME: 'SET_KANA_LAST_NAME',
  SET_LATIN_GIVEN_NAME: 'SET_LATIN_GIVEN_NAME',
  SET_LOCATION_COUNTRY: 'SET_LOCATION_COUNTRY',
  SET_REGISTERED_CART: 'SET_REGISTERED_CART',
  NEW_CART: 'NEW_CART',
  CREATE_CART: 'CREATE_CART',
  UPDATE_CART: 'UPDATE_CART',
};

export const actions = {
  setConsumer:
    /**
     * This action takes in a profile ID, and sets it.
     * TODO: make me take and set a consumer object
     *
     * @param {String} profileId – the profile ID to set in state
     */
    (profileId) => ({
      type: actionTypes.SET_CONSUMER,
      profileId,
    }),

  setVisitorId:
    /**
     * This action takes in a visitor ID, and sets it.
     *
     * @param {String} visitorId – the visitor ID to set in state
     */
    (visitorId) => ({
      type: actionTypes.SET_VISITOR_ID,
      visitorId,
    }),

  setIdentityConsumerType:
    /**
     * This action takes in a consumerType, and sets it.
     *
     * @param {String} consumerType – the consumer type to set in state
     */
    (consumerType) => ({
      type: actionTypes.SET_CONSUMER_TYPE,
      consumerType,
    }),

  setIdentityConsumerEmail:
    /**
     * This action takes in a consumerEmail, and sets it.
     *
     * @param {String} consumerEmail – the consumer email to set in state
     */
    (consumerEmail) => ({
      type: actionTypes.SET_CONSUMER_EMAIL,
      consumerEmail,
    }),

  setIdentityConsumerName:
    /**
     * This action takes in a consumerName, and sets it.
     *
     * @param {String} consumerName – the consumer name to set in state
     */
    (consumerName) => ({
      type: actionTypes.SET_CONSUMER_NAME,
      consumerName,
    }),

  setIdentityKanaFirstName:
    /**
     * This action takes in a kanaFirstName, and sets it.
     *
     * @param {String} kanaFirstName – the kana first name to set in state
     */
    (kanaFirstName) => ({
      type: actionTypes.SET_KANA_FIRST_NAME,
      kanaFirstName,
    }),

  setIdentityKanaLastName:
    /**
     * This action takes in a kanaLastName, and sets it.
     *
     * @param {String} kanaLastName – the kana last name to set in state
     */
    (kanaLastName) => ({
      type: actionTypes.SET_KANA_LAST_NAME,
      kanaLastName,
    }),

  setIdentityGivenName:
    /**
     * This action takes in a kanaLastName, and sets it.
     *
     * @param {String} kanaLastName – the kana last name to set in state
     */
    (latinGivenName) => ({
      type: actionTypes.SET_LATIN_GIVEN_NAME,
      latinGivenName,
    }),

  setIdentityCountry:
    /**
     * This action takes in a kanaLastName, and sets it.
     *
     * @param {String} kanaLastName – the kana last name to set in state
     */
    (locationCountry) => ({
      type: actionTypes.SET_LOCATION_COUNTRY,
      locationCountry,
    }),

  newCart:
    /**
     * This action creates a new cart, LOCALLY (unregistered).
     */
    () => ({
      type: actionTypes.NEW_CART,
    }),

  createCart:
    /**
     * This action creates a consumer's cart with an uuid and data.
     *
     * @param {Uuid} id – the generated cart id
     * @param {Array} data – the cart data
     */
    (id, data) => {
      return {
        type: actionTypes.CREATE_CART,
        id,
        data,
      };
    },

  updateCart:
    /**
     * This action updates the consumer's cart with new data.
     * @param {Array} data – the cart data
     */
    (data) => ({
      type: actionTypes.UPDATE_CART,
      data,
    }),

  setRegisteredCart:
    /**
     * This action sets the cart to be registered or unregistered
     * @param {Bool} bool – the bool to set the registeredCart boolean in state
     */
    (bool) => ({
      type: actionTypes.SET_REGISTERED_CART,
      bool,
    }),
};

export default actions;
