import React, { useContext, useState } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';

import DotComCartModal from './dotComCartModal';
import translations from './infoDrawer.i18n';
import SidebarItem from './sidebarItem';

/**
 * Component rendering the Nike.com Cart Button & Modal, managing display and permissions
 * logic that determines whether or not the button and/or modal is visible.
 *
 * @component
 *
 * @param {object} props - A React props object
 * @param {string} props.profileId - Consumer's profileId
 * @param {string} props.consumerType - Consumer's type (e.g. 'GUEST', 'SWOOSH')
 *
 * @returns {React.ReactNode}
 */
const DotComCart = ({ profileId, consumerType }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { i18nString } = useContext(NikeI18nContext);
  const { VIEW_NIKE_DOT_COM_CART } = mapValues(translations, i18nString);

  const classes = useStyles();

  if (!profileId || consumerType === 'GUEST') {
    return null;
  }

  return (
    <>
      <SidebarItem
        title={
          <Link
            data-testid='view-nike-dot-com-cart-button'
            component='button'
            aria-label={VIEW_NIKE_DOT_COM_CART}
            color='primary'
            onClick={() => setModalOpen(true)}
            className={classes.button}>
            {VIEW_NIKE_DOT_COM_CART}
          </Link>
        }
      />
      {modalOpen && <DotComCartModal isOpen={modalOpen} closeModal={() => setModalOpen(false)} />}
    </>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: '40px',
    cursor: 'pointer',
    fontSize: '1em',
  },
}));

export default DotComCart;
