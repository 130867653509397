import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core';

export default function SizeDropDown({ handleSizeSelect, selectedId, itemId, sizeSpecs }) {
  const classes = useStyles();
  const [selectedSkuId, setSelectedSkuId] = React.useState(selectedId);
  const handleChange = (event) => {
    setSelectedSkuId(event.target.value);
    handleSizeSelect(itemId, event.target.value);
  };
  // don't load size dropdown when skuid is not available
  if (!sizeSpecs.map((size) => size.hasOwnProperty('skuId')).includes(true)) {
    return <></>;
  }

  return (
    <Select
      labelId='sku-size-select-label'
      id={`sku-size-select-${itemId}`}
      defaultValue={selectedSkuId}
      value={selectedSkuId}
      label='size'
      className={classes.sizeDropDown}
      onChange={handleChange}>
      {Array.isArray(sizeSpecs) &&
        sizeSpecs.map((sku, i) => (
          <MenuItem key={i} value={sku.skuId}>
            {sku.localizedSize || sku.size}
          </MenuItem>
        ))}
    </Select>
  );
}

SizeDropDown.propTypes = {
  sizeSpecs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      gtin: PropTypes.string,
      itemId: PropTypes.string,
      nikeSize: PropTypes.string,
    })
  ).isRequired,
};

const useStyles = makeStyles(({ palette }) => ({
  grid: {
    margin: '0.5rem 0',
  },
  sizeDropDown: {
    paddingLeft: '10px',
  },
  gridItem: {
    'borderRadius': '4px',
    'justifyContent': 'center',
    'alignItems': 'center',
    'textAlign': 'center',
    'display': 'flex',
    'width': '77px',
    'height': '48px',
    'padding': '14px 10px',
    'border': 'none',
    'backgroundColor': palette.grey[100],
    '&.oos': {
      // linear gradient creates diagonal line through the card
      background: `linear-gradient(to bottom right, ${palette.background.paper}, ${palette.background.paper} 49%, ${palette.grey[300]} 49%, ${palette.grey[300]} 51%, ${palette.background.paper} 49%)`,
      color: palette.grey[700],
    },
    '&.selected': {
      backgroundColor: palette.common.black,
      color: palette.common.white,
    },
  },
}));
