import gql from 'graphql-tag';

const MERCH_PRODUCT_BY_ID_QUERY = gql`
  query getMerchProductById($productId: String!) {
    merchProductById(productId: $productId) {
      id
      snapshotId
      modificationDate
      status
      merchGroup
      colorCode
      styleType
      styleCode
      styleColor
      mainColor
      pid
      exclusiveAccess
      catalogId
      hideFromCSR
      resourceType
      links {
        self {
          ref
        }
      }
      valueAddedServices {
        id
        vasType
      }
      quantityLimit
    }
  }
`;

export default MERCH_PRODUCT_BY_ID_QUERY;
