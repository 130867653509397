const translations = {
  CASE_RECORD_NOT_FOUND: {
    stringKey: 'caseRecordNotFound',
    primaryValue: 'Case record not found',
    description: 'Error message when case record is not found',
  },
  CLEARANCE: {
    stringKey: 'clearance',
    primaryValue: 'Clearance',
    description: 'Product label for a clearance item',
  },
  COMING_SOON: {
    stringKey: 'comingSoon',
    primaryValue: 'Coming Soon',
    description: 'label for products that will be available soon',
  },
  EXCLUSIVE_ACCESS: {
    stringKey: 'exclusiveAccess',
    primaryValue: 'Reserved for You',
    description: 'Error message when case record is not found',
  },
  HOLD: {
    stringKey: 'onHold',
    primaryValue: 'On Hold',
    description: "label for products that don't have available stock but may eventually",
  },
  SIZE: {
    stringKey: 'size',
    primaryValue: 'size',
    description: "Product info label for an item's size.",
  },
  TOKEN_EXCHANGE_SERVICE_ERROR: {
    stringKey: 'tokenExchangeServiceError',
    primaryValue: 'Error calling token exchange service',
    description: 'Error message when calling token exchange service',
  },
  TOKEN_EXPIRED: {
    stringKey: 'chinaTokenExpired',
    primaryValue: 'Token expired. Please go back and start again.',
    description: 'Error message when okta times outs in china app',
  },
  MEMBER_ACCESS: {
    stringKey: 'memberAccess',
    primaryValue: 'Member Access',
    description: 'Product label for Member Access',
  },
  NO_SIZE_DATA_FOR_CART_ITEM: {
    stringKey: 'noSizeDataForCartItem',
    primaryValue: 'No size data found for cart item',
    description:
      'error message to display when failing to fetch size data for specific cart item via merchSkus API',
  },
  OUT_OF_STOCK: {
    stringKey: 'outOfStock',
    primaryValue: 'Out of Stock',
    description: 'Product label for OOS sku',
  },
};

export default translations;
