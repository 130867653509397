/** React / Utils */
import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/** Local */
import { noboReducer } from '../reducers/noboReducer';
import { actions } from '../actions/noboActions';
import { getOktaToken } from '../../utils/adminToken';
import { getConsumerDelegatedToken } from '../../utils/consumerDelegatedToken';
import { useGetUrlParams } from '../../hooks/useGetUrlParams';
import useSearchQuery from '../../hooks/useSearchQuery';
import useSnacks from '../../hooks/useSnack';
import translations from './../../components/shared/shared.i18n';

export const NOBOTokenContext = createContext([{}, () => {}]);
export const defaultState = {};

const { Provider } = NOBOTokenContext;

/**
 * A provider for NOBO token
 *
 * @param {Object} props  – React props
 */
export const NOBOProvider = ({ children }) => {
  const [state, reducer] = useReducer(noboReducer, defaultState);
  const caseId = useGetUrlParams('caseId');
  const { inChinaRegion } = useSearchQuery();
  const { setError } = useSnacks();
  const { i18nString } = useContext(NikeI18nContext);
  const { TOKEN_EXPIRED, CASE_RECORD_NOT_FOUND, TOKEN_EXCHANGE_SERVICE_ERROR } = mapValues(
    translations,
    i18nString
  );

  const errorCodes = {
    404: CASE_RECORD_NOT_FOUND,
    500: TOKEN_EXPIRED,
    default: TOKEN_EXCHANGE_SERVICE_ERROR,
  };

  const fetchConsumerDelegatedToken = async (oktaToken, snackMessage = false) => {
    try {
      const response = await getConsumerDelegatedToken(caseId);
      if (response) {
        reducer(actions.setNoboToken(response));
      }
    } catch (error) {
      setError(
        errorCodes[error?.message?.toString()] ||
          `${errorCodes['default']} - ${error?.message?.toString()}`
      );
    }
  };

  const oktaToken = async () => await getOktaToken();

  useEffect(async () => {
    if ((await oktaToken()) && inChinaRegion()) {
      fetchConsumerDelegatedToken(await getOktaToken());
    }
  }, []);

  const newState = { ...state };
  return <Provider value={[newState, reducer]}>{children}</Provider>;
};

export default NOBOTokenContext;
