export const actionTypes = {
  SET_NOBO_TOKEN: 'SET_NOBO_TOKEN',
};

export const actions = {
  setNoboToken:
    /**
     * This action sets the NOBO access token
     *
     * @param {String} token – NOBO access token
     */
    (token) => ({
      type: actionTypes.SET_NOBO_TOKEN,
      token,
    }),
};

export default actions;
