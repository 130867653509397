import { actionTypes } from '../actions/consumerActions';
import { v4 as uuidv4 } from 'uuid';
import { calculateCartTotal } from '../../utils/cart';

export const consumerInitialState = {
  consumerName: '',
  consumerEmail: '',
  consumerType: '',
  profileId: '',
  visitorId: '',
  locationCountry: '',
  registeredCart: true,
  cart: {
    id: '',
    length: 0,
    data: [],
  },
};

/**
 * The reducer for the consumer context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const consumerReducer = (state = consumerInitialState, action = {}) => {
  switch (action.type) {
    /*
    set a consumer's profile id, and set the isRegistered state to the
    truthiness of that value
    */
    case actionTypes.SET_CONSUMER: {
      const profileId = action.profileId;
      if (profileId) {
        return {
          ...state,
          profileId,
          visitorId: state.visitorId || uuidv4(),
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case actionTypes.SET_VISITOR_ID: {
      return {
        ...state,
        visitorId: action.visitorId,
      };
    }

    case actionTypes.SET_CONSUMER_TYPE: {
      return {
        ...state,
        consumerType: action.consumerType,
        /*
         Marking user as registered user based on consumerType which passed 
         after calling Identity Api
        */
      };
    }
    case actionTypes.SET_CONSUMER_NAME: {
      return {
        ...state,
        consumerName: action.consumerName,
      };
    }
    case actionTypes.SET_CONSUMER_EMAIL: {
      return {
        ...state,
        consumerEmail: action.consumerEmail,
      };
    }
    case actionTypes.SET_LATIN_GIVEN_NAME: {
      return {
        ...state,
        latinGivenName: action.latinGivenName,
      };
    }
    case actionTypes.SET_LOCATION_COUNTRY: {
      return {
        ...state,
        locationCountry: action.locationCountry,
      };
    }
    case actionTypes.SET_REGISTERED_CART: {
      return {
        ...state,
        registeredCart: action.bool,
      };
    }
    case actionTypes.NEW_CART: {
      return {
        ...state,
        registeredCart: false,
        cart: {
          id: '',
          length: 0,
          data: [],
        },
      };
    }
    case actionTypes.CREATE_CART: {
      const { id, data } = action;

      if (id && data) {
        return {
          ...state,
          registeredCart: true,
          cart: {
            id: id,
            length: calculateCartTotal(data.items) || 0,
            data: data,
          },
        };
      } else {
        return state;
      }
    }
    case actionTypes.UPDATE_CART: {
      const { data } = action;
      if (data) {
        const length = calculateCartTotal(data.items);
        return {
          ...state,
          cart: {
            id: data.id,
            length,
            data,
          },
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};
