/**
 * Function to calculate cart total by each item's quantity
 *
 * @param {Array} items - items in cart
 */
export const calculateCartTotal = (items) => {
  const count = (acc, item) => {
    return acc + item.quantity;
  };

  return items?.reduce(count, 0);
};
