/* React/JS */
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import React, { useEffect, useState, useContext } from 'react';
import { ApolloProvider } from 'react-apollo';
import { useOktaAuth } from '@okta/okta-react/src/OktaContext';
import decodeToken from 'jwt-decode';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/* Local */
import config from '../../utils/config';
import { classifyOperation } from '../../utils/newRelic';
import { getAdminToken, getOktaToken } from './../../utils/adminToken';
import { useGetUrlParams } from '../../hooks/useGetUrlParams';
import useSnacks from '../../hooks/useSnack';
import useSearchQuery from '../../hooks/useSearchQuery';
import { SessionStorage } from './../../constants/consumerDelegatedToken.const';
import translations from './../../components/shared/shared.i18n';
import { isConsumerDelegatedTokenValid } from './../../utils/consumerDelegatedToken';
import { getValue } from './../../utils/sessionStorage';
/**
 * A place to store all of our context providers for (otherwise) cleaner code.
 *
 * @param {object} props – the React.props object passed in to this component
 */
export const DockyardProvider = ({ children }) => {
  const { authState } = useOktaAuth();
  const { accessToken, idToken } = authState;
  const [client, setClient] = useState(null);
  const { setNetworkError, setError } = useSnacks();
  const caseId = useGetUrlParams('caseId');
  const { inChinaRegion } = useSearchQuery();
  const { i18nString } = useContext(NikeI18nContext);
  const { TOKEN_EXPIRED } = mapValues(translations, i18nString);

  useEffect(() => {
    if (idToken && !accessToken && inChinaRegion()) {
      setError(TOKEN_EXPIRED);
      window.localStorage.removeItem('nike.cs.obo.ocobo');
    }
    /* 
		When authState is populated (by Okta), we'll grab the access token from it, and create our
		Apollo Client with it as our Authorization token.
    */
    const uri = config.foundry.grandProxy;
    const httpLink = createHttpLink({
      uri,
    });

    // Called for every request, sends custom tracing to New Relic.
    const newRelicLink = setContext(async (req, { headers }) => {
      // Below: 'newrelic' is defined by injected snippet in index.js
      if (window.newrelic && !inChinaRegion()) {
        const adminToken = await getAdminToken(caseId);
        const { cs_case_caseid, cs_case_userType, sub, uid } = decodeToken(adminToken);
        // other attributes give us more information about the interaction
        window.newrelic.setCustomAttribute('caseId', cs_case_caseid);
        window.newrelic.setCustomAttribute('userType', cs_case_userType);
        window.newrelic.setCustomAttribute('athleteEmail', sub);
        window.newrelic.setCustomAttribute('athleteId', uid);
        window.newrelic.addPageAction(req.operationName, {
          type: classifyOperation(req.operationName),
          status: 200,
        });
      }

      if (inChinaRegion()) {
        const delegatedFromSession = JSON.parse(
          sessionStorage.getItem(SessionStorage.CaseHeader)
        )?.[`cs_case_${caseId}`]?.token?.access_token;

        // Below: 'newrelic' is defined by injected snippet in index.js
        if (window.newrelic && delegatedFromSession) {
          const { cs_case_caseid, cs_case_userType, sub, uid } =
            decodeToken(delegatedFromSession) || {};
          // other attributes give us more information about the interaction
          window.newrelic.setCustomAttribute('caseId', cs_case_caseid);
          window.newrelic.setCustomAttribute('userType', cs_case_userType);
          window.newrelic.setCustomAttribute('athleteEmail', sub);
          window.newrelic.setCustomAttribute('athleteId', uid);
          window.newrelic.addPageAction(req.operationName, {
            type: classifyOperation(req.operationName),
            status: 200,
          });
        }
      }
    });

    // AuthLink is called every time an apollo call is made
    const authLink = setContext(async (req, { headers }) => {
      const delegatedToken = getValue(SessionStorage.CaseHeader)?.[`cs_case_${caseId}`];
      const delegatedAccessToken = `Bearer ${delegatedToken?.token?.access_token}`;

      if (delegatedToken && delegatedAccessToken) {
        if (!isConsumerDelegatedTokenValid(delegatedAccessToken)) {
          throw new Error(`${TOKEN_EXPIRED}`);
        }
      }
      const oktaToken = await getOktaToken();
      const authToken = inChinaRegion() ? oktaToken?.accessToken : await getAdminToken(caseId);
      const { sub } = decodeToken(authToken);
      // add in the auth header
      const appHeaders = {
        ...headers,
        'athleteEmail': sub,
        'authorization': authToken,
        'x-nike-auth-token': inChinaRegion() ? delegatedAccessToken : null,
      };

      return {
        headers: appHeaders,
      };
    });

    /* 
		this middleware simply stops calls which do not have auth in place yet (which happens when
		the app is loading)
		*/
    const requireAuthMiddleware = new ApolloLink((operation, forward) => {
      if (inChinaRegion()) {
        const delegatedToken = JSON.parse(sessionStorage.getItem(SessionStorage.CaseHeader))?.[
          `cs_case_${caseId}`
        ];
        const delegatedAccessToken = delegatedToken?.token?.access_token;
        // check the validity of the delegated token when available
        if (delegatedAccessToken) {
          return accessToken && isConsumerDelegatedTokenValid(delegatedAccessToken)
            ? forward(operation)
            : false;
        }
      }
      return accessToken ? forward(operation) : false;
    });

    /*
		TODO: This errorLink allows us to perform certain actions anytime GraphQL Errors or Network
		Errors occur, so that we can handle them gracefully. We still have to figure out how to only
		handle them here when we are not already handling them, or to only handle errors that would
		otherwise break the application. For now, we are only logging them.
		*/
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (window.newrelic) {
            return window.newrelic.addPageAction(`${operation?.operationName}-graphQlError`, {
              operation: operation?.operationName,
              message: error.message,
              status: error.extensions?.response?.status,
            });
          }
          console.error(`GraphQL Error: ${operation?.operationName} ${error.message}`);
        });
      }
      if (networkError) {
        setNetworkError(networkError?.result?.message);
        console.error(`Network Error: ${operation.operationName} ${networkError.message}`);
      }
    });

    setClient(
      new ApolloClient({
        link: ApolloLink.from([errorLink, newRelicLink, authLink, requireAuthMiddleware, httpLink]),
        uri,
        connectToDevTools: true,
        cache: new InMemoryCache({
          addTypename: false,
        }),
      })
    );
  }, [accessToken]);

  // once we have an Apollo Client, we populate the app with our providers, and their children
  return client && <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default DockyardProvider;
