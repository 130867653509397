import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CssBaseline } from '@material-ui/core';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

if (process.env.REACT_APP_ENV !== 'prod') {
  // TODO: replace timeout when reactAxe migrates to axe-core-npm and fixes issue #92
  // https://github.com/dequelabs/axe-core-npm/issues/92
  const axe = require('@axe-core/react');
  // forces axe to wait until page has loaded before testing
  setTimeout(() => axe(React, ReactDOM, 1000), 1000);
}
