/* React/Utils */
import React, { useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/* Material-UI */
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Link from '@material-ui/core/Link';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

/* Local */
import { SessionStorage } from '../../../constants/consumerDelegatedToken.const';
import { ViewMemberOffers } from '../../../constants/permissions.const';
import IDENTITY_USER_MUTATION from '../../../graphql/mutations/identifyUser.mutation';
import consumerActions from '../../../store/actions/consumerActions';
import { CaseContext } from '../../../store/contexts/caseContext';
import { ConsumerContext } from '../../../store/contexts/consumerContext';
import { NOBOTokenContext } from '../../../store/contexts/noboTokenContext';
import { useGetUrlParams } from '../../../hooks/useGetUrlParams';
import useSearchQuery from '../../../hooks/useSearchQuery';
import useSnack from '../../../hooks/useSnack';
import { getValue } from '../../../utils/sessionStorage';
import HasPermission from '../hasPermission';
import DotComCart from './dotComCart';
import translations from './infoDrawer.i18n';
import SidebarItem from './sidebarItem';
/**
 * Member access sidebar item
 */
const Member = () => {
  const { i18nString } = useContext(NikeI18nContext);
  const { ERROR, ARIA_PROFILE_INFO, GUEST, SHOPPING_FOR, MEMBER_OFFERS } = mapValues(
    translations,
    i18nString
  );
  const { setError } = useSnack();
  const classes = useStyles();
  const [consumerState, consumerDispatch] = useContext(ConsumerContext);
  const cartId = useGetUrlParams('cartId');
  const caseId = useGetUrlParams('caseId');
  const marketplace = useGetUrlParams('mp');
  const { isChina } = useSearchQuery();

  const {
    setIdentityConsumerType,
    setIdentityConsumerEmail,
    setIdentityConsumerName,
    setIdentityCountry,
    setConsumer,
    setVisitorId,
  } = consumerActions;

  const { upmId, visitorId } = useContext(CaseContext);
  const [NOBOState] = useContext(NOBOTokenContext);

  const [getIdentityUser] = useMutation(IDENTITY_USER_MUTATION, {
    // If the error is 404, consumerType is GUEST, otherwise show error in SNACK.
    onError: (err) => {
      if (err.message && err.message.includes('error: 404')) {
        consumerDispatch(setIdentityConsumerName(GUEST));
      } else {
        setError(`${ERROR} ${err.message}`);
      }
    },
    onCompleted: (idnData) => {
      consumerDispatch(setIdentityConsumerEmail(idnData.identityUser?.contact?.email?.address));
      consumerDispatch(setIdentityConsumerType(idnData.identityUser?.userType));
      consumerDispatch(setIdentityCountry(idnData.identityUser?.location?.country));
      consumerDispatch(
        setIdentityConsumerName(
          `${idnData.identityUser?.name?.latin?.given || ''} 
           ${idnData.identityUser?.name?.latin?.family || ''}`
        )
      );
      consumerDispatch(setConsumer(upmId));
    },
    notifyOnNetworkStatusChange: true,
  });

  /**
   * This useEffect calls IdnAccount API (with upmId argument).
   */
  useEffect(() => {
    const sessionToken = getValue(SessionStorage.CaseHeader)?.[`cs_case_${caseId}`];
    /*
    reading profile id from session storage as case context.
    some times case context is not updated with latest token details
    */
    const profileId = sessionToken?.case?.profileId;
    const consumerId = upmId || profileId;
    if (consumerId) {
      getIdentityUser({
        variables: {
          id: consumerId,
          useProd: true,
          input: {
            fields: [
              'name.latin.given',
              'name.latin.family',
              'contact.email.address',
              'location.country',
              'userType',
            ],
          },
        },
      });
    } else {
      // setting the visitor that we get from okta token
      consumerDispatch(setVisitorId(visitorId));
      consumerDispatch(setIdentityConsumerName(GUEST));
    }
  }, [upmId, NOBOState?.token]);

  const consumerInfo = [
    consumerState.consumerName,
    consumerState.consumerEmail,
    consumerState.consumerType,
    consumerState.profileId,
    consumerState.locationCountry,
  ];

  return (
    <>
      <SidebarItem
        dataTestId='shoppingForSection'
        title={SHOPPING_FOR}
        icon={<AccountCircleIcon />}>
        <Typography aria-label={ARIA_PROFILE_INFO} component={'ul'}>
          <>
            {consumerInfo.map(
              (info, i) =>
                info && (
                  <li key={i} className={classes.consumerInfoItems}>
                    {info}
                  </li>
                )
            )}
          </>
        </Typography>
      </SidebarItem>
      <DotComCart consumerType={consumerState?.consumerType} profileId={consumerState?.profileId} />
      {consumerState.consumerType && consumerState.consumerType !== 'GUEST' && !isChina() && (
        <HasPermission permission={ViewMemberOffers}>
          <SidebarItem
            title={
              <Link
                component={RouterLink}
                className={classes.memberOffersLink}
                data-testid='memberOffersLink'
                to={`/member/offers/?mp=${marketplace}&caseId=${caseId}&cartId=${cartId}`}>
                {MEMBER_OFFERS}
              </Link>
            }
            icon={<LoyaltyIcon />}
          />
        </HasPermission>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  consumerInfoItems: {
    listStyleType: 'none',
    marginTop: theme.spacing(1),
  },
  memberOffersLink: {
    display: 'block',
    color: theme.palette.common.purple,
    cursor: 'pointer',
    fontSize: '1em',
  },
}));

export default Member;
