/* React/Utils */
import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';

/* Local */
import AppI18nProvider from './store/providers/i18NProvider';
import DockyardProvider from './store/providers/dockyardProvider';
import { PermissionProvider } from './store/contexts/permissionContext';
import { ProductDetailsProvider } from './store/contexts/productDetailsContext';
import { ComposeComponents as AppProviders } from './utils/reactUtils';
import { AthleteProvider } from './store/contexts/athleteContext';
import { SearchProvider } from './store/contexts/searchContext';
import { CaseProvider } from './store/contexts/caseContext';
import { SettingsProvider } from './store/contexts/settingsContext';
import { ConsumerProvider } from './store/contexts/consumerContext';
import { NOBOProvider } from './store/contexts/noboTokenContext';
import { DotComCartProvider } from './store/contexts/dotComCartContext';
import theme from './theme';

/**
 * This higher-order component in which to hold all React Context providers for the app.
 *
 * @param {object} props – A React props object.
 */
const Providers = ({ children }) => {
  const appProviders = [
    DockyardProvider, // need consumer token
    CaseProvider, // need nobo
    AthleteProvider,
    SearchProvider,
    PermissionProvider,
    ProductDetailsProvider,
    ConsumerProvider,
    NOBOProvider,
    DotComCartProvider,
  ];

  return (
    <SettingsProvider>
      <AppI18nProvider>
        <AppProviders components={appProviders}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </AppProviders>
      </AppI18nProvider>
    </SettingsProvider>
  );
};

export default Providers;
