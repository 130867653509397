/**
 * Formats date/time by locale.
 *
 * @param date - any value that can parse as a date
 * @param [dateOnly] - true if only want to display date, no time
 * @param [locale] - string of ISO locale code
 * @returns {string}
 */
export const format = (date, dateOnly, locale) => {
  if (!date) return;

  date = new Date(date);
  if (date.toString() === 'Invalid Date') return;

  /*
  If the locale string for some reason does not contain the character '_' 
  then use the original locale value in localDate/localString conversion
  */
  if (locale.lastIndexOf('_') > 0) {
    locale = locale.substring(0, locale.lastIndexOf('_')).replace('_', '-');
  }

  return dateOnly ? date.toLocaleDateString(locale) : date.toLocaleString(locale).replace(',', '');
};

/**
 *
 * @param {*} date - any value that can parse as a date
 * @param {*} [locale] - string of ISO locale code
 * @returns {string}
 */
export const formatDateTime = (date, locale = 'en-US') => {
  return format(date, false, locale);
};

/**
 * gives you an arbitrarily, or specifically later date
 * @param {number} timeDelta minutes change from the current time, defaults to an hour later
 * @returns {Date}
 */
export const dateDiff = (time = 3600) => {
  const now = new Date();
  const newTime = new Date(now.getTime() + time * 1000);

  return newTime;
};
