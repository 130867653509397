/* React/Utils */
import { useContext, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/* Material-UI */
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { CircularProgress } from '@material-ui/core';
import Link from '@material-ui/core/Link';

/* Local */
import translations from './memberOffers.i18n';
import { formatDateTime } from '../../../utils/date';
import useSnack from '../../../hooks/useSnack';
import { useGetUrlParams } from '../../../hooks/useGetUrlParams';
import useSearchQuery from '../../../hooks/useSearchQuery';
import MERCH_PRODUCT_BY_ID_QUERY from '../../../graphql/queries/merchProductById.query';
import MERCH_CONTENT_BY_STYLE_COLOR_QUERY from '../../../graphql/queries/merchContentByStyleColor';
import LANGUAGE_QUERY from '../../../graphql/queries/languageByMarketplace.query';

const MemberOfferTableRow = ({ offer, index }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { LANGUAGE_ERROR } = mapValues(translations, i18nString);
  const { setError } = useSnack();
  const [styleColor, setStyleColor] = useState(null);
  const [style, setStyle] = useState(null);
  const [productTitle, setProductTitle] = useState('');
  const [catalogId, setCatalogId] = useState(null);

  const isPromo = offer?.items?.[0]?.item?.type?.includes('PROMO');
  const productId = offer?.items[0]?.item?.itemId;
  const orderNumber = offer?.items[0]?.redemptions?.[0]?.item?.orderNumber;
  const cartId = useGetUrlParams('cartId');
  const caseId = useGetUrlParams('caseId');
  const mpInUrl = useGetUrlParams('mp');
  const { isChina } = useSearchQuery();
  const defaultMarketplace = isChina() ? 'CN' : 'US';
  const marketplace = mpInUrl || defaultMarketplace;

  // useEffect that gets locale to pass to getProductTitle
  useEffect(() => {
    if (marketplace && styleColor) {
      getLocale({ variables: { marketplace } });
    }
  }, [marketplace, styleColor]);

  // useEffect that call merch product api based on productId
  useEffect(() => {
    if (productId && offer?.items?.[0]?.item?.type === 'PRODUCT') {
      getProductStyleColor({
        variables: {
          productId: productId,
        },
      });
    }
  }, []);

  // Lazy query to get product style color info
  const [getProductStyleColor] = useLazyQuery(MERCH_PRODUCT_BY_ID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (styleData) => {
      // this sets the style data for the product
      const currentStyleColor = styleData.merchProductById.styleColor;
      const styleCode = styleData.merchProductById.styleCode;
      const currentCatalogId = styleData.merchProductById.catalogId;
      setStyleColor(currentStyleColor);
      setStyle(styleCode);
      setCatalogId(currentCatalogId);
    },
    onError: (error) => {
      // Just console logging as we don't want to throw error if style color info is not available
      console.log('error in getting style color', error);
    },
  });

  // Lazy query to get product title
  const [getProductTitle, { data: titleData }] = useLazyQuery(MERCH_CONTENT_BY_STYLE_COLOR_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProductTitle(data?.merchContentByStyleColor[styleColor]?.fullTitle);
    },
    onError: (error) => {
      // Just console logging as we don't want to throw error if product title is not available
      console.log('error in getting product title', error);
    },
  });

  // Lazy query to get locale
  const [getLocale] = useLazyQuery(LANGUAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      getProductTitle({
        variables: {
          country: marketplace,
          locale: data.marketplaceLanguages.localeMappings[0].locale,
          styleColor: styleColor,
        },
      });
    },
    onError: (error) => {
      setError(`${LANGUAGE_ERROR} ${error.message}`);
    },
  });

  /**
   * handles the product click from member offers table
   * @param {*} catalogId - catalogId of the product that is clicked
   */
  const handleOrderClick = (catalogId) => {
    return `${window.location.protocol}//${window.location.host}/product/${catalogId}/${style}/details/?mp=${mpInUrl}&caseId=${caseId}&cartId=${cartId}`;
  };

  // Returns product title if available and shows loading icon while retrieving it.
  const displayProductTitle = () => {
    return (
      <TableCell component='th' scope='row'>
        {productTitle || titleData ? (
          <Link data-testid={`product_title_${index}`} href={handleOrderClick(catalogId)}>
            {productTitle || ' '}
          </Link>
        ) : (
          <CircularProgress />
        )}
      </TableCell>
    );
  };

  return (
    <TableRow>
      <TableCell>{offer?.items[0] ? offer?.items[0]?.item?.type : null}</TableCell>
      <TableCell>
        {isPromo && offer?.items[0]?.redemptions?.length > 0 ? 'Redeemed' : 'Not Redeemed'}
      </TableCell>
      {isPromo ? (
        <TableCell>{offer?.items?.[0]?.item?.promoCode}</TableCell>
      ) : (
        displayProductTitle()
      )}
      {isPromo && <TableCell> {offer?.items?.[0]?.item?.promoCode}</TableCell>}
      {!isPromo && <TableCell> {styleColor ? styleColor : <CircularProgress />}</TableCell>}
      <TableCell>{formatDateTime(offer?.startDate)}</TableCell>
      <TableCell>{formatDateTime(offer?.endDate)}</TableCell>
      {!isPromo && <TableCell>{orderNumber}</TableCell>}
    </TableRow>
  );
};

MemberOfferTableRow.propTypes = {
  offer: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        item: PropTypes.shape({
          orderNumber: PropTypes.string,
          promoType: PropTypes.string,
          promoCode: PropTypes.string,
          type: PropTypes.string,
        }),
        redemptions: PropTypes.string,
        totalRedemptions: PropTypes.string,
      })
    ),
  }).isRequired,
  index: PropTypes.number,
};

export default MemberOfferTableRow;
