import { useContext, useMemo } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash.mapvalues';

const useMemoTranslations = (translations) => {
  const { i18nString } = useContext(NikeI18nContext);
  return useMemo(() => mapValues(translations, i18nString), [translations, i18nString]);
};

export default useMemoTranslations;
