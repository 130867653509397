import React, { createContext, useReducer } from 'react';
import { searchReducer, searchInitialState } from '../reducers/searchReducer';

export const SearchContext = createContext([{}, () => {}]);
const { Provider } = SearchContext;

export const SearchProvider = ({ children }) => {
  const [state, reducer] = useReducer(searchReducer, searchInitialState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};

export default SearchContext;
