import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Typography } from '@material-ui/core';
import { ConsumerContext } from '../../../store/contexts/consumerContext';

/**
 * Custom implementation of the Material UI Bag icon, with a cart quantity superimposed.
 */
export default function CartIcon() {
  const classes = useStyles();
  const [consumerState] = useContext(ConsumerContext);
  const cartQuantity = consumerState.cart?.length || 0;

  return (
    <>
      <Typography className={classes.overlay}>{cartQuantity}</Typography>
      <SvgIcon data-testid='mini-cart'>
        <path d='M19 6h-2c0-2.76-2.24-5-5-5S7 3.24 7 6H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-7-3c1.66 0 3 1.34 3 3H9c0-1.66 1.34-3 3-3zm0 10' />
      </SvgIcon>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  overlay: {
    color: theme.palette.grey[100],
    textAlign: 'center',
    position: 'absolute',
    top: '3px',
    fontSize: '1em',
    fontWeight: 800,
  },
}));
