import gql from 'graphql-tag';

const GET_CART = gql`
  query getCart($id: String!) {
    getCart(id: $id) {
      id
      country
      currency
      brand
      channel
      items {
        id
        skuId
        productId
        level
        quantity
        priceInfo {
          price
          subtotal
          discount
          valueAddedServices
          total
          msrp
          fullPrice
        }
        valueAddedServices {
          id
          priceInfo {
            price
            discount
            total
          }
        }
      }
      promotionCodes
      promotionCodesDetails {
        code
        displayName
        discountTotal
        status
        reasons {
          code
        }
      }
      totals {
        subtotal
        valueAddedServicesTotal
        discountTotal
        total
        quantity
      }
    }
  }
`;

export default GET_CART;
