import { actionTypes } from '../actions/dotComCartActions';

export const dotComCartInitialState = {
  items: {},
  error: '',
};

/**
 * The reducer for the dot com cart context
 *
 * @param {object} state - the current state of the context
 * @param {object} action - an identifier and payload for the specific action to be taken
 */
export const dotComCartReducer = (state = dotComCartInitialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_DOT_COM_CART: {
      const items = {};
      action.cartData.items.forEach((item) => {
        items[item.id] = {
          ...item,
          isSelected: action.cartData.items.length === 1 ? true : false,
        };
      });
      return {
        ...state,
        ...action.cartData,
        items,
      };
    }

    case actionTypes.SET_SIZE_SKU_IDS: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.id]: {
            ...state.items[action.id],
            alternativeSizes: state.items[action.id]?.alternativeSizes.map((altSize) => {
              const { skuId, localizedSize } = action.sizeData[altSize.gtin];
              return { ...altSize, skuId, localizedSize };
            }),
          },
        },
      };
    }

    case actionTypes.SELECT_ITEM: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.id]: {
            ...state.items[action.id],
            isSelected: true,
          },
        },
      };
    }

    case actionTypes.UNSELECT_ITEM: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.id]: {
            ...state.items[action.id],
            isSelected: false,
          },
        },
      };
    }

    case actionTypes.SELECT_ITEM_SIZE: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.id]: {
            ...state.items[action.id],
            selectedSku: action.skuId,
          },
        },
      };
    }

    case actionTypes.UPDATE_DOTCOM_CART: {
      if (!action.cartItemIds) return state;
      Object.entries(state.items).forEach((item) => {
        if (!action.cartItemIds.includes(item[1].id)) {
          delete state.items[item[0]];
        }
      });
      return state;
    }

    case actionTypes.SET_DOTCOM_MODAL_ERROR: {
      return {
        ...state,
        error: action.errorMessage,
      };
    }

    case actionTypes.SET_STAGED_ITEMS: {
      return {
        ...state,
        stagedItems: action.items,
      };
    }

    default: {
      return state;
    }
  }
};
