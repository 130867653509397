/** React / Utils */
import React, { useContext, useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from 'react-apollo';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import { SessionStorage } from '../../../constants/consumerDelegatedToken.const';
import LANGUAGE_QUERY from '../../../graphql/queries/languageByMarketplace.query';
import useGetUrlParams from '../../../hooks/useGetUrlParams';
import useMemoTranslations from '../../../hooks/useMemoTranslations';
import useSearchQuery from '../../../hooks/useSearchQuery';
import useSnack from '../../../hooks/useSnack';
import { actions as searchActions } from '../../../store/actions/searchActions';
import NOBOTokenContext from '../../../store/contexts/noboTokenContext';
import SearchContext from '../../../store/contexts/searchContext';
import { getAdminToken } from '../../../utils/adminToken';
import SnackBar from '../snackBar';
import Loading from '../loading';
import InfoDrawer from '../infoDrawer';
import translations from './withTopBar.i18n';

const TopBar = lazy(() => import('./topBar'));

/**
 * A higher-order component that wraps all views, and includes layout and accessibility
 * features, as well as the SnackBar component.
 *
 * @param {React.Component} children – the content to go inside the layout
 * @param {bool} [startBig=false] (optional) boolean describing whether the TopBar should be big
 */

const WithTopBar = ({ children, startBig = false }) => {
  const classes = useStyles();
  const [noboState] = useContext(NOBOTokenContext);
  const [searchState, searchDispatch] = useContext(SearchContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const caseId = useGetUrlParams('caseId');
  const { setError } = useSnack();
  const { inChinaRegion } = useSearchQuery();
  const mp = useGetUrlParams('mp');
  const { selectedCountry, languageArray, cartCurrency } = searchState;

  const { setLanguage, setCartCurrency, setLanguageArray } = searchActions;

  const { ARIA_MAIN_APP, LANGUAGE_ERROR } = useMemoTranslations(translations);

  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const noboAccessToken = noboState?.token;
  const toggleInfoDrawer = () => setInfoDrawerOpen(!infoDrawerOpen);
  useEffect(() => {
    // close infoDrawer on landing page
    if (location.pathname === '/') {
      setInfoDrawerOpen(false);
    }
  }, [location]);

  useEffect(async () => {
    try {
      inChinaRegion()
        ? setIsAuthenticated(
            Boolean(
              JSON.parse(sessionStorage.getItem(SessionStorage.CaseHeader))?.[`cs_case_${caseId}`]
                ?.token?.access_token
            ) || Boolean(noboState?.token)
          )
        : setIsAuthenticated(Boolean(await getAdminToken(caseId)));
    } catch (err) {
      setError(
        err?.message?.toString() ||
          'Incorrect caseId or expired token. Please go back and start again.'
      );
    }
  }, [noboAccessToken]);

  const [getLanguage] = useLazyQuery(LANGUAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ marketplaceLanguages: data }) => {
      const defaultLocale = data.localeMappings.find(
        ({ languageId }) => languageId === data.defaultLanguage
      ).locale;
      const languagesWithLocale = data.languages.map((languageObject) => ({
        ...languageObject,
        locale: data.localeMappings.find(({ languageId }) => languageId === languageObject.id)
          .locale,
      }));
      searchDispatch(setLanguage(data.defaultLanguage, defaultLocale));
      searchDispatch(setCartCurrency(data.currency.currencyCode));
      searchDispatch(setLanguageArray(languagesWithLocale));
    },
    onError: (error) => {
      setError(`${LANGUAGE_ERROR} ${error.message}`);
    },
  });

  useEffect(() => {
    if (mp !== selectedCountry || !languageArray.length || !cartCurrency) {
      return getLanguage({ variables: { marketplace: selectedCountry } });
    }
  }, [selectedCountry]);

  return (
    <div className={classes.root}>
      <div id='scrolling-box' className={classes.page} data-testid='with-layout-page'>
        <TopBar
          startBig={startBig}
          handleInfoDrawer={toggleInfoDrawer}
          infoDrawerOpen={infoDrawerOpen}
        />
        <SnackBar />
        <div aria-label={ARIA_MAIN_APP} className={classes.main} role='main'>
          {isAuthenticated ? children : <Loading />}
        </div>
      </div>
      {isAuthenticated && <InfoDrawer open={infoDrawerOpen} />}
    </div>
  );
};

WithTopBar.propTypes = {
  children: PropTypes.node,
  startBig: PropTypes.bool,
};

export default WithTopBar;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflowX: 'hidden',
    width: '100vw',
  },
  page: {
    flex: '1 1 auto',
    overflow: 'auto',
    backgroundColor: theme.palette.grey[100],
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
}));
