/* React/Utils */
import React, { useContext, useEffect } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import { useHistory } from 'react-router';

/* Material-UI */
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

/* Local */
import CartIcon from './cartIcon.js';
import { ConsumerContext } from '../../../store/contexts/consumerContext';
import SidebarItem from './sidebarItem';
import translations from './infoDrawer.i18n';
import useCart from '../../../hooks/useCart';
import useSnack from '../../../hooks/useSnack';
import { useGetUrlParams } from '../../../hooks/useGetUrlParams';
import useSearchQuery from '../../../hooks/useSearchQuery';
import SettingsContext from '../../../store/contexts/settingsContext';
import { isActive } from '../../../utils/consumerDelegatedToken';

/**
 * Cart component rendered within the infoDrawer
 */
const Cart = () => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { VIEW_CART, NO_CART, CASE_ID_MISSING_ERROR } = mapValues(translations, i18nString);

  const [consumerState] = useContext(ConsumerContext);
  const [settingsState] = useContext(SettingsContext);
  const { fetchCart, createEmptyCart } = useCart({});
  const history = useHistory();
  const { setError } = useSnack();
  const { generatePath } = useSearchQuery();

  const cartIdInUrl = useGetUrlParams('cartId');
  const caseIdInUrl = useGetUrlParams('caseId');
  const mpInUrl = useGetUrlParams('mp');
  const lang = useGetUrlParams('lang');

  const languageMap = {
    en_US: 'en',
    ja: 'ja',
    zh_CN: 'zh-Hans',
    ko: 'ko',
  };

  // Todo: Move this code to an util so it can be reused across the app
  const cartLanguage = settingsState?.language || languageMap[lang] || 'en';

  // sends pageAction to new Relic to help record time in flow data
  const onViewCartClick = () => {
    if (window.newrelic) {
      try {
        const jsonOktaData = localStorage.getItem('nike.cs.obo.ocobo');
        const oktaData = JSON.parse(jsonOktaData);
        const idToken = oktaData?.idToken;
        const caseAccessToken = oktaData[`cs_case_${caseIdInUrl}`]?.accessToken;
        window.newrelic.addPageAction('viewCart', {
          type: 'tokenValidation',
          isCaseAccessTokenExpired: caseAccessToken ? !isActive(caseAccessToken) : true,
          isIdTokenExpired: idToken ? !isActive(idToken) : true,
          status: 200,
        });
      } catch {
        window.newrelic.addPageAction('viewCart', {
          type: 'cartApp',
          status: 200,
        });
      }
    }
  };

  /*
    checks various url parameters to replace, set defaults, or preform actions as needed
  */
  useEffect(() => {
    if (!caseIdInUrl) {
      // throw error if no caseId in the url, user needs to return to SD to gain caseId

      setError(`${CASE_ID_MISSING_ERROR}`);
    } else if (caseIdInUrl && !cartIdInUrl) {
      // no cart id set, create new unregistered cart and push to url

      const newCartId = createEmptyCart();
      history.push(`${generatePath({ cartId: newCartId })}`);
      if (window.newrelic) {
        // adding cartId to newrelic as a custom attribute lets us sort users actions by session
        window.newrelic?.setCustomAttribute('cartId', newCartId);
      }
    } else if (caseIdInUrl && cartIdInUrl) {
      if (window.newrelic) {
        // adding cartId to newrelic as a custom attribute lets us sort users actions by session
        window.newrelic?.setCustomAttribute('cartId', cartIdInUrl);
      }
      if (Boolean(consumerState.registeredCart) && Boolean(!consumerState.cart.id)) {
        // grabs cart if the cartId in url is registered and a valid cart is not already in state
        fetchCart(cartIdInUrl);
      } else if (!mpInUrl) {
        // if no marketplace set, set to US as default

        history.push(`${generatePath({ mp: 'US' })}`);
      }
    }
  }, []);

  return (
    <SidebarItem
      dataTestId='cartSection'
      title={
        consumerState.cart?.length > 0 ? (
          <Link
            className={classes.viewCart}
            data-testid='viewCart'
            onClick={onViewCartClick}
            // using an anchor prevents requiring a manual reload
            href={`${window.location.protocol}//${window.location.host}/portlets/checkout/${caseIdInUrl}/${consumerState.cart.id}/cart?locale=${cartLanguage}`}>
            {VIEW_CART}
          </Link>
        ) : (
          <Typography
            aria-label={NO_CART}
            className={classes.noCart}
            component={'ul'}
            data-testid='noCart'>
            <li>{NO_CART}</li>
          </Typography>
        )
      }
      icon={<CartIcon />}></SidebarItem>
  );
};

const useStyles = makeStyles((theme) => ({
  viewCart: {
    display: 'block',
    color: theme.palette.common.purple,
    cursor: 'pointer',
    fontSize: '1em',
  },
  noCart: { fontSize: '1em', listStyleType: 'none', padding: 0 },
}));

export default Cart;
