import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import Cart from './cart';
import Member from './member';
import translations from './infoDrawer.i18n';

const drawerOpenWidth = 350;
const drawerCloseWidth = 0;

/**
 * Holds Profile and shopping cart info that is toggled open from the topBar
 *
 * @param {bool} open boolean to control if the drawer is open
 */
export default function InfoDrawer({ open }) {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { ARIA_OBO_INFO_DRAWER } = mapValues(translations, i18nString);

  return (
    <Drawer
      variant='persistent'
      data-testid='info-drawer'
      PaperProps={{ elevation: 6 }}
      SlideProps={{ direction: 'left' }}
      aria-label={ARIA_OBO_INFO_DRAWER}
      classes={{
        paper: clsx(classes.drawerPaper, open && classes.drawerOpen, !open && classes.drawerClose),
      }}
      className={classes.infoDrawer}
      open={open}
      role='region'
      anchor='right'>
      <Cart />
      <Member />
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  infoDrawer: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 2,
  },
  drawerPaper: {
    position: 'relative',
    paddingTop: theme.spacing(1),
  },
  drawerOpen: {
    width: `${drawerOpenWidth}px`,
  },
  drawerClose: {
    width: `${drawerCloseWidth}px`,
  },
}));
