import React from 'react';
import { NumberFormat } from '@nike/i18n-react';
import { makeStyles } from '@material-ui/core';

/**
 * Shared component to display amount types formatted with the correct currency
 * ie amount = '4.00', currency = 'USD' this would return '$4.00'
 * @param {*} amount – the price or monetary value you want displayed
 * @param {String} currency – currency that the amount should be displayed with (USD, EUR, etc.)
 * @param {*} discountAmount - optional. the price or monetary value for a discount offered
 */
export const FormattedCurrency = ({ amount, currency, discountAmount = null }) => {
  const classes = useStyles();
  amount = Number(amount) || 0;
  if (discountAmount) {
    discountAmount = Number(discountAmount) || 0;
    return (
      <>
        <NumberFormat number={discountAmount} currency={currency} currencyDisplay='symbol' />
        <del className={classes.strikeThrough}>
          <NumberFormat number={amount} currency={currency} currencyDisplay='symbol' />
        </del>
      </>
    );
  }
  return <NumberFormat number={amount} currency={currency} currencyDisplay='symbol' />;
};

const useStyles = makeStyles((theme) => ({
  strikeThrough: {
    color: theme.palette.grey[600],
    paddingLeft: theme.spacing(0.5),
  },
}));
