/**
 * Map of GraphQL operations to human-readable operation types that an be interpreted
 * and analyzed by New Relic.
 *
 * ALL new GraphQL operations should be added to an appropriate category
 * Categories can be added and rearranged if run by the team
 */
const operationTypeMap = {
  browse: ['assetSearchV2', 'getReasonCodesV2', 'rollUpThreadsV2', 'getCart'],
  shop: ['createCart', 'updateCart', 'putGiftMessage', 'getCartView'],
  giftCard: ['giftCardPDP', 'giftCardDetail'],
  identity: ['postReadIdentityUser'],
  lostDemand: ['postLostDemand'],
  nikeByYou: ['nikeByYouPDP'],
  offers: ['getMemberAccessInvitesV2', 'getMerchContentByStyleColor', 'getMerchProductById'],
  PDP: [
    'storeViewsByZip',
    'getThreadByThreadIdv3',
    'getThreadsByRollupKeyv3',
    'storeLocationByZip',
    'storeLocationByCity',
  ],
  recommendNavs: ['postRecommendNavigations'],
  language: ['getMarketplaceLanguages'],
};

/**
 * converts the above operationTypeMap to a usable dictionary of operation names with
 * the assigned operation type as the definition
 *
 * @param {object} obj – operationTypeMap
 */
function reverseMap(obj) {
  return Object.keys(obj).reduce(
    (acc, type) =>
      obj[type].reduce((a, num) => {
        a[num] = type;
        return a;
      }, acc),
    {}
  );
}

/**
 * classifies any given operation name by its operation type based on the operationTypeMap
 *
 * @param {object} obj – operationTypeMap
 */
export const classifyOperation = (operation) => {
  const typeLookup = reverseMap(operationTypeMap);
  const foundType = typeLookup[operation];

  if (foundType) {
    return foundType;
  } else {
    console.error(
      // eslint-disable-next-line no-template-curly-in-string
      `Unclassified OperationType, please add ${operation} to the operationTypeMap in ./utils/newRelic`
    );
    return 'unclassified';
  }
};
