import { logErrorAction } from '../newrelic/logErrorAction';

/**
 * This function safely retrieves a value form session, parsing it as JSON if possible
 *
 * @param {string} key – a key to retrieve a value against from localStorage
 */
export const getValue = (key) => {
  let result = null;
  if (!key) return result;
  const stringValue = sessionStorage.getItem(key);
  try {
    result = JSON.parse(stringValue);
  } catch (error) {
    result = stringValue;
    logErrorAction(error, { customMessage: 'getValue, cs-order-creation session storage' });
  } finally {
    return result;
  }
};
