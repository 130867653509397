/* React/Utils */
import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import { v4 as uuidv4 } from 'uuid';

/* Material-UI */
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

/* Local */
import translations from './memberOffers.i18n';
import OfferRow from './offerRow';
import Loading from '../../shared/loading';
import Warning from '../../shared/warning';
import WithTopBar from '../../shared/withTopBar';
import useSnacks from '../../../hooks/useSnack';
import MEMBER_ACCESS_INVITES_V2_QUERY from '../../../graphql/queries/memberAccessInvitesV2.query';
import { CaseContext } from '../../../store/contexts/caseContext';
import { Channels } from './../../../constants/channels.const';
import { ConsumerContext } from '../../../store/contexts/consumerContext';
import { useGetUrlParams } from '../../../hooks/useGetUrlParams';

/**
 * Component housing the Member Offers Page
 */
const MemberOffers = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setError } = useSnacks();
  const { i18nString } = useContext(NikeI18nContext);
  const [consumerState] = useContext(ConsumerContext);
  const { upmId } = useContext(CaseContext);
  const channelId = Channels.MemberOffers;
  const [randomUpmId] = useState(uuidv4());

  const [memberAccessInvites, setMemberAccessInvites] = useState(null);
  const [isProductOfferAvailable, setIsProductOfferAvailable] = useState(false);
  const [isPromoAvailable, setIsPromoAvailable] = useState(false);

  const marketplace = useGetUrlParams('mp') || 'US';

  const {
    ACCESS_CODES,
    COLOR_STYLE,
    END_DATE,
    EXIT,
    INVITES_ERROR,
    OFFER_TYPE,
    MEMBER_OFFERS,
    NO_OFFERS_AVAILABLE,
    ORDER_NUMBER,
    PRODUCT_NAME,
    PROMO_CODES,
    PROMO_NAME,
    PROMO_CODE,
    START_DATE,
    STATUS,
  } = mapValues(translations, i18nString);

  /**
   * Apollo query that will retrieve the member access invites using the marketplace and channelId.
   */
  const { loading, data } = useQuery(MEMBER_ACCESS_INVITES_V2_QUERY, {
    variables: {
      marketplace,
      channelId,
    },
    fetchPolicy: 'network-only',
    context: {
      headers: {
        upmid: upmId ? upmId : randomUpmId,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const memberAccessData = data.memberAccessInvitesV2.objects;
      setMemberAccessInvites(memberAccessData);
      setIsProductOfferAvailable(
        memberAccessData.filter((data) => data?.items?.[0]?.item?.type === 'EVENT').length > 0 ||
          memberAccessData.filter((data) => data?.items?.[0]?.item?.type === 'PRODUCT').length > 0
      );
      setIsPromoAvailable(
        memberAccessData.filter((data) => data?.items?.[0]?.item?.type?.includes('PROMO')).length >
          0
      );
    },
    onError: (error) => {
      setError(`${INVITES_ERROR} ${error.message}`);
    },
  });
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      history.goBack();
    }
  };

  if (loading) {
    return (
      <WithTopBar>
        <Loading />
      </WithTopBar>
    );
  } else if (data) {
    return (
      <WithTopBar>
        <a
          data-testid='exit-link'
          className={classes.exitLink}
          onClick={() => history.goBack()}
          onKeyPress={onKeyPress}
          role='button'
          tabIndex={0}>
          <ArrowLeftIcon fontSize='large' />
          {EXIT}
        </a>
        <div className={classes.offersPage}>
          <Typography variant='h1' className={classes.pageTitle}>
            {MEMBER_OFFERS}
          </Typography>
          {consumerState && (
            <address className={classes.consumerInfo}>
              {consumerState.consumerName}
              <br />
              {consumerState.consumerEmail}
              <br />
              {upmId}
            </address>
          )}
          {isProductOfferAvailable && (
            <Card className={classes.cardSpacing} elevation={3} data-testid='access-codes-card'>
              <CardContent>
                <CardHeader
                  disableTypography
                  title={
                    <Typography className={classes.memberTableTitle}>{ACCESS_CODES}</Typography>
                  }
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{OFFER_TYPE}</TableCell>
                      <TableCell>{STATUS}</TableCell>
                      <TableCell>{PRODUCT_NAME}</TableCell>
                      <TableCell>{COLOR_STYLE}</TableCell>
                      <TableCell>{START_DATE}</TableCell>
                      <TableCell>{END_DATE}</TableCell>
                      <TableCell>{ORDER_NUMBER}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(memberAccessInvites) &&
                      memberAccessInvites
                        .filter((offer) => offer?.items?.[0]?.item?.type === 'PRODUCT')
                        .map((offer, index) => (
                          <OfferRow key={offer.id} offer={offer} index={index} />
                        ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}

          {isPromoAvailable && (
            <Card className={classes.cardSpacing} elevation={3} data-testid='promo-codes-card'>
              <CardContent>
                <CardHeader
                  disableTypography
                  title={
                    <Typography className={classes.memberTableTitle}>{PROMO_CODES}</Typography>
                  }
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{OFFER_TYPE}</TableCell>
                      <TableCell>{STATUS}</TableCell>
                      <TableCell>{PROMO_NAME}</TableCell>
                      <TableCell>{PROMO_CODE}</TableCell>
                      <TableCell>{START_DATE}</TableCell>
                      <TableCell>{END_DATE}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(memberAccessInvites) &&
                      memberAccessInvites
                        .filter((offer) => offer?.items?.[0]?.item?.type?.includes('PROMO'))
                        .map((offer) => <OfferRow key={offer.id} offer={offer} />)}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}

          {!isProductOfferAvailable && !isPromoAvailable ? (
            <Card className={classes.cardSpacing} elevation={3} data-testid='access-codes-card'>
              <CardContent>
                <Typography data-testid='no-offers-msg'>{NO_OFFERS_AVAILABLE}</Typography>{' '}
              </CardContent>{' '}
            </Card>
          ) : null}
        </div>
      </WithTopBar>
    );
  } else {
    // TODO: make a prettier error page (Ideally we should we should never get here)
    return <Warning message={'an unknown error has occurred'} />;
  }
};

const useStyles = makeStyles((theme) => ({
  cardSpacing: {
    margin: '1.25rem',
  },
  consumerInfo: {
    marginLeft: theme.spacing(3),
  },
  pageTitle: {
    ...theme.typography.h3,
    fontSize: '2.25rem',
    margin: '1.25rem',
  },
  memberTableTitle: {
    fontSize: '1.75em',
  },
  exitLink: {
    color: theme.palette.common.purple,
    cursor: 'pointer',
    display: 'flex',
    placeItems: 'center',
    marginLeft: '1%',
    width: 200,
  },
}));

export default MemberOffers;
