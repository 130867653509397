const newRelic = window.newrelic || {};

/**
 * A function for captureing errors in catch blocks and sending to New Relic
 *
 * @param {Error} error - The error being caught and sent to New Relic
 * @param  {Object} data - Optional. Name/value pairs representing custom attributes
 * to send to New Relic
 */
export function logErrorAction(error, data) {
  if (process.env.REACT_APP_ENV === 'prod') {
    // Log errors/messages in production
    if (!error.stack && Error.captureStackTrace) {
      Error.captureStackTrace(error, Error);
    }

    newRelic.noticeError(error, {
      ...data,
    });
  } else {
    console.error(error);
    console.log(data);
  }
}
