const translations = {
  ADD_CART_ERROR: {
    description: 'user error on adding to cart',
    primaryValue: 'Error adding to cart:',
    stringKey: 'addToCartError',
  },
  ADD_CART_ERROR_MISSING_AD_GROUP: {
    description: 'user error on adding to cart without App.DigitalCommerce.Buy.Checkout',
    primaryValue: 'Please request the following AD group: App.DigitalCommerce.Buy.Checkout:',
    stringKey: 'addToCartErrorMissingADGroup',
  },
  GET_CART_ERROR: {
    description: 'user error on fetching cart',
    primaryValue: 'Error fetching cart:',
    stringKey: 'getCartError',
  },
  ADD_TO_CART: {
    stringKey: 'addToCart',
    primaryValue: 'Add to Cart',
    description: 'Button label for adding to the cart',
  },
  CART_ID_MISSING_ERROR: {
    stringKey: 'cartIdMissingError',
    primaryValue: 'Error: No cart id found. Please close the tab and retry',
    description: 'cart id missing in the url',
  },
  CASE_ID_MISSING_ERROR: {
    stringKey: 'caseIdMissing',
    primaryValue: 'Case id is missing in the url',
    description: 'error message when case id is missing. Please close the tab and retry',
  },
  LANGUAGE_ERROR: {
    stringKey: 'languageError',
    primaryValue: 'Error getting language data for marketplace:',
    description: 'error message to show when language data could not be found',
  },
  ERROR_ADDING_TO_OCOBO_CART: {
    stringKey: 'addToOcoboCartError',
    primaryValue: 'Item(s) failed moving from Nike.com cart to OMOBO cart.',
    description:
      'error message when request to add items from .com cart to ocobo cart is unsuccessful',
  },
  SUCCESS_ADDING_TO_OCOBO_CART: {
    stringKey: 'addToOcoboCartSuccess',
    primaryValue: 'Item(s) successfully moved from Nike.com cart to OMOBO cart.',
    description: 'message when items successfully added to ocobo cart from .com cart',
  },
};

export default translations;
