const translations = {
  ARIA_OBO_INFO_DRAWER: {
    description: 'aria label for open obo info drawer',
    primaryValue: 'Shopping For',
    stringKey: 'oboInfoDrawer',
  },
  ARIA_PROFILE_INFO: {
    stringKey: 'ariaProfileInfo',
    primaryValue: 'profile information',
    description: 'aria label for profile information',
  },
  CART: {
    description: 'title for label of mini-cart on shop page',
    primaryValue: 'Cart',
    stringKey: 'cart',
  },
  ERROR: {
    stringKey: 'memberError',
    primaryValue: 'Error trying to fetch consumer details',
    description: 'error message if the identity call fails',
  },
  ERROR_ADDING_TO_OCOBO_CART: {
    stringKey: 'addToOcoboCartError',
    primaryValue: 'Item(s) failed moving from Nike.com cart to OMOBO cart.',
    description:
      'error message when request to add items from .com cart to ocobo cart is unsuccessful',
  },
  GUEST: {
    stringKey: 'guest',
    primaryValue: 'Guest',
    description: 'text for consumers without accounts',
  },
  SHOPPING_FOR: {
    stringKey: 'oboInfoDrawer',
    primaryValue: 'Shopping For',
    description: 'aria label for open obo info drawer',
  },
  MEMBER_OFFERS: {
    stringKey: 'memberOffers',
    primaryValue: 'Member Offers',
    description: 'Link to view current offers for members',
  },
  NO_CART: {
    stringKey: 'noCart',
    primaryValue: 'Empty Cart',
    description: 'Message to display when there are no items in the cart',
  },
  VIEW_CART: {
    stringKey: 'viewCart',
    primaryValue: 'View Cart',
    description: 'Text for View Cart',
  },
  CASE_ID_MISSING_ERROR: {
    stringKey: 'caseIdMissing',
    primaryValue: 'Case id is missing in the url',
    description: 'error message when case id is missing. Please close the tab and retry',
  },
  VIEW_NIKE_DOT_COM_CART: {
    stringKey: 'viewNikeDotComCart',
    primaryValue: 'View Nike.com Cart',
    description: 'label for button that opens a modal displaying nike.com cart info',
  },
  CLOSE: {
    stringKey: 'close',
    primaryValue: 'Close',
    description: 'label to close Price Mod Return Dialog',
  },
  ADD_TO_CART: {
    stringKey: 'addToCart',
    primaryValue: 'Add to Cart',
    description: 'Button label for adding to the cart',
  },
  NIKE_DOT_COM_CART: {
    stringKey: 'nikeDotComCart',
    primaryValue: 'Nike.com Cart',
    description: 'Button label for adding to the cart',
  },
  SELECT_ALL: {
    stringKey: 'selectAll',
    primaryValue: 'Select All',
    description: 'aria label for checkbox whose purpose is to select all items in the list',
  },
  NO_DOT_COM_CART_AVAILABLE: {
    stringKey: 'noDotComCartAvailable',
    primaryValue: 'No Nike.com cart available',
    description:
      'message when a consumer does not have a cart on nike.com for athletes to take over',
  },
};

export default translations;
