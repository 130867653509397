import { useOktaAuth } from '@okta/okta-react/src/OktaContext';
import React, { createContext, useEffect, useState } from 'react';

export const AthleteContext = createContext([{}, () => {}]);
const { Provider } = AthleteContext;

/**
 * A read-only context for athlete information
 *
 * @param {Object} props  – React props
 */
export const AthleteProvider = ({ children }) => {
  const { authState, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      authService.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, authService]); // Update if authState changes

  return <Provider value={[{ ...userInfo }, () => {}]}>{children}</Provider>;
};

export default AthleteContext;

/* 
email: "Alan.Ampersand@nike.com"
email_verified: true
family_name: "Ampersand"
given_name: "Alan"
groups: ["App.Forge.Developers"]
locale: "US"
name: "Alan Ampersand"
preferred_username: "Alan.Ampersand@nike.com"
sub: "00u8cb84yaVtjqZkj0h7"
updated_at: 1603290598
zoneinfo: "America/Los_Angeles"
*/
