import React, { createContext, useContext } from 'react';

import { NikeI18nProvider } from '@nike/i18n-react';
import translations from './../../lib/translations.json';
import { supportedLanguages, sanitizeTranslations } from './../../utils/translations';
import SettingsContext from './../contexts/settingsContext';
export const I18nContext = createContext({});
const { Provider } = I18nContext;

/**
 * This is a provider for the Nike i18n context, with the requisite attributes filled in.
 *
 * @param {Object} React.props – a React props object
 */
export const AppI18nProvider = ({ children }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [settingsState] = useContext(SettingsContext);

  // a map of the values provided by SwooshDesk and their i18n-compatible equivalents
  const languageMap = {
    en_US: 'en',
    ja: 'ja',
    zh_CN: 'zh-Hans',
    ko: 'ko',
  };

  const language = urlParams.get('lang');
  const i18NnLang = settingsState.language || languageMap[language];
  const currentLanguage = i18NnLang || 'en';

  return (
    <Provider value={[{ language: currentLanguage }]}>
      <NikeI18nProvider
        currentLanguageTag={currentLanguage}
        supportedLanguages={supportedLanguages}
        translations={sanitizeTranslations(translations)}>
        {children}
      </NikeI18nProvider>
    </Provider>
  );
};

export default AppI18nProvider;
