/**
 * Function to remove excess whitespace from translation strings
 * @param {object} translationObj translation object string object returned by bodega-pull service.
 */
export const sanitizeTranslations = (translationObj) => {
  const sanitized = translationObj.translations.map((obj) => {
    obj.key = obj.key.replace(/\s/g, '');
    for (let property in obj) {
      obj[property] = obj[property].trim();
    }
    return obj;
  });
  translationObj.translations = sanitized;
  return translationObj;
};

export const supportedLanguages = [
  {
    name: 'Chinese',
    tag: 'zh-Hans',
  },
  {
    name: 'English (US)',
    tag: 'en',
  },
  {
    name: 'Japanese',
    tag: 'ja',
  },
  {
    name: 'Spanish',
    tag: 'es-419',
  },
  {
    name: 'Korean',
    tag: 'ko',
  },
];
