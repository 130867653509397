import gql from 'graphql-tag';

const MEMBER_ACCESS_INVITES_V2_QUERY = gql`
  query getMemberAccessInvitesV2($marketplace: String!, $channelId: String!, $filters: [Filter]) {
    memberAccessInvitesV2(marketplace: $marketplace, channelId: $channelId, filters: $filters) {
      objects {
        id
        contentId
        startDate
        endDate
        items {
          item {
            type
            itemId
            promoCode
            accessCode
            description
          }
          redemptions {
            date
            item {
              type
              orderNumber
              skuId
              itemId
              promoCode
            }
          }
          startDate
          endDate
        }
        resourceType
        links {
          self {
            ref
          }
        }
      }
    }
  }
`;

export default MEMBER_ACCESS_INVITES_V2_QUERY;
