/* React/JS */
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

/** material UI */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

/** Local */
import { ApiTimeOut } from '../../../constants/cqrsConstant';
import { GiftCardTypes } from '../../../constants/giftCard.const';
import { SnackStatuses } from '../../../constants/snack.const';
import CART_VIEW_QUERY from '../../../graphql/queries/getViewCart.query';
import useCart from '../../../hooks/useCart';
import { useGetUrlParams } from '../../../hooks/useGetUrlParams';
import useMemoTranslations from '../../../hooks/useMemoTranslations';
import useSnack from '../../../hooks/useSnack';
import translations from './infoDrawer.i18n';
import CartItem from '../cartItem';
import dotComCartActions from '../../../store/actions/dotComCartActions';
import CaseContext from '../../../store/contexts/caseContext';
import DotComCartContext from '../../../store/contexts/dotComCartContext';
import SearchContext from '../../../store/contexts/searchContext';
import ConsumerContext from '../../../store/contexts/consumerContext';

/**
 * gives an athlete the opportunity to view their cart items while shopping
 * @param {Function} closeModal function that runs when modal is closed
 * @param {Boolean} isOpen indicator for whether or not the modal is displayed
 * is rendered
 * @returns a react modal
 */
const DotComCartModal = ({ closeModal, isOpen }) => {
  const [consumerState] = useContext(ConsumerContext);
  const [dotComCart, dotComCartDispatch] = useContext(DotComCartContext);
  const {
    setDotComCart,
    setDotComModalError,
    selectItem,
    selectItemSize,
    unselectItem,
    setStagedItems,
  } = dotComCartActions;
  const { upmId } = useContext(CaseContext);
  const marketplace = useGetUrlParams('mp');
  const caseId = useGetUrlParams('caseId');
  const cartId = useGetUrlParams('cartId');
  const [searchState] = useContext(SearchContext);
  const { locale } = searchState;
  const classes = useStyles();
  const { setError } = useSnack();
  const {
    AddToCartButton,
    createCartCallDotComModal,
    updateCartCallDotComModal,
    updateDotComCartLoading,
  } = useCart({ followUpDotComCartUpdateComplete });

  function followUpDotComCartUpdateComplete() {
    closeModal();
  }

  const history = useHistory();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const {
    ADD_TO_CART,
    CLOSE,
    DOT_COM_CART_ERROR,
    NIKE_DOT_COM_CART,
    NO_DOT_COM_CART_AVAILABLE,
    SELECT_ALL,
    ERROR_ADDING_TO_OCOBO_CART,
  } = useMemoTranslations(translations);

  /**
   * This useEffect hook closes DotCom modal immediately after successfully adding
   * DotCom Cart items to the consumer's OMOBO Shop Cart.
   */
  const prevLength = usePrevious(consumerState.cart?.length ?? 0);
  useEffect(() => {
    if (consumerState.cart.length) {
      if (dotComCart.stagedItems?.length && consumerState.cart.length > prevLength) {
        closeModal();
      }
    }
  }, [consumerState.cart.length]);

  const { loading } = useQuery(CART_VIEW_QUERY, {
    skip: !locale || !marketplace,
    variables: {
      input: {
        request: {
          cart: {
            country: marketplace,
            brand: 'NIKE',
            channel: 'NIKECOM',
          },
          locale: locale,
        },
      },
      timeout: ApiTimeOut,
    },
    context: {
      headers: {
        upmid: upmId,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) return;
      const error = data?.getCartView.error;
      if (error) {
        setError(`${DOT_COM_CART_ERROR} ${error.message}`);
        return;
      }
      dotComCartDispatch(setDotComCart(data?.getCartView?.response));
    },
    onError: (error) => {
      setError(`${DOT_COM_CART_ERROR} ${error.message}`);
    },
  });

  const handleSelectAll = () => {
    const isAnySelected = Object.values(dotComCart.items).filter((item) => item.isSelected);

    Object.keys(dotComCart.items).forEach((id) => {
      dotComCartDispatch(isAnySelected.length ? unselectItem(id) : selectItem(id));
    });
  };

  const handleSelectItem = useCallback((id) => {
    const { isSelected } = dotComCart.items[id];
    dotComCartDispatch(isSelected ? unselectItem(id) : selectItem(id));
  });

  const handleSelectItemSize = useCallback((id, skuId) => {
    const shouldUnselect = dotComCart.items[id].selectedSku === skuId;
    dotComCartDispatch(selectItemSize(id, shouldUnselect ? null : skuId));
  });

  useEffect(() => {
    const firstSkuId = Object.values(dotComCart.items)[0]?.skuId;
    const firstSelectedSku = Object.values(dotComCart.items)[0]?.selectedSku;
    if (firstSkuId && !firstSelectedSku) {
      Object.values(dotComCart.items).forEach((item) => {
        dotComCartDispatch(selectItemSize(item.id, item.skuId));
      });
    }
  }, [dotComCart.items]);

  const memoCartItems = useMemo(() => Object.values(dotComCart.items), [dotComCart.items]);

  const handleDotComItemsAddToCart = () => {
    const selectedItems = Object.values(dotComCart.items)
      .filter((item) => item.isSelected)
      .map((item) => {
        const {
          giftCard,
          id,
          offer,
          productDetails: { productType, styleColor },
          productId,
          quantity,
          selectedSku: skuId,
          valueAddedServices,
        } = item;

        const itemData = new Map();
        itemData.set('caseId', caseId);
        itemData.set('cartId', cartId);
        itemData.set('marketplace', marketplace);
        itemData.set('styleColor', styleColor);
        itemData.set('productId', productId);
        itemData.set('source', history.location.pathname);
        const cartItem = {
          id,
          skuId,
          quantity,
          itemData,
        };
        // Handle items with VAS data
        if (valueAddedServices?.length) {
          cartItem.valueAddedServices = valueAddedServices?.map((vasItem) => ({
            id: vasItem.id,
            instruction: vasItem.instruction,
          }));
        }

        // Handle GiftCard Data
        if ([GiftCardTypes.DIGITAL, GiftCardTypes.PHYSICAL].includes(productType)) {
          cartItem.giftCard = giftCard;
          if (GiftCardTypes.DIGITAL === productType) {
            // isolate specific values for digital giftcard
            const {
              recipient: { firstName, lastName },
              shippingAddress: { email, country },
            } = item;
            cartItem.recipient = { firstName, lastName };
            cartItem.shippingAddress = { email, country };
          }
        }

        // Handle cart items with offer for Nike Member
        if (offer) {
          cartItem.offer = offer;
        }

        return cartItem;
      });

    /**
     * Setting staged items here defines the cart items that should be removed from
     * the consumer's DotCom Cart immediately after they are successfully added to the
     * consumer's OMOBO Shop Cart.
     */
    dotComCartDispatch(setStagedItems(selectedItems));

    if (selectedItems.length) {
      if (consumerState.cart?.id) {
        updateCartCallDotComModal({
          variables: {
            id: cartId,
            input: selectedItems.map((item) => ({
              op: 'add',
              path: '/items',
              // change item.id to new uuid for adding to ocobo cart
              value: { ...item, id: uuidv4() },
            })),
          },
        });
      } else {
        createCartCallDotComModal({
          variables: {
            id: cartId,
            input: {
              id: cartId,
              country: marketplace || 'US',
              brand: 'NIKE',
              channel: `NIKECOM_${cartId}`,
              currency: dotComCart.currency,
              items: selectedItems.map((item) => {
                // change item.id to new uuid for adding to ocobo cart
                return { ...item, id: uuidv4() };
              }),
            },
          },
        });
      }
    }
  };

  const showSelectAll = !loading && Boolean(Object.keys(dotComCart?.items).length);

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isOpen}
      onClose={closeModal}
      aria-label={NIKE_DOT_COM_CART}>
      <DialogTitle>
        {NIKE_DOT_COM_CART}
        {dotComCart.error && (
          <Alert
            severity={SnackStatuses.ERROR}
            data-testid='dot-com-cart-error'
            onClose={() => dotComCartDispatch(setDotComModalError(''))}>
            {ERROR_ADDING_TO_OCOBO_CART}: {dotComCart.error}
          </Alert>
        )}
        {showSelectAll && (
          <FormControlLabel
            className={classes.selectAll}
            control={
              <Checkbox
                data-testid='select-all-dot-com-items-checkbox'
                checked={
                  Object.keys(dotComCart?.items).length ===
                  Object.values(dotComCart?.items).filter((item) => item.isSelected).length
                }
                onChange={handleSelectAll}
                color='primary'
              />
            }
            label={SELECT_ALL}
          />
        )}
      </DialogTitle>
      {!loading ? (
        <DialogContent className={classes.itemsContainer}>
          {memoCartItems.length ? (
            <List>
              {memoCartItems.map((item) => (
                <CartItem
                  key={item.id}
                  item={item}
                  selectItem={handleSelectItem}
                  selectItemSize={handleSelectItemSize}
                  selectable={true}
                  currency={dotComCart.currency}
                  country={dotComCart.country}
                />
              ))}
            </List>
          ) : (
            NO_DOT_COM_CART_AVAILABLE
          )}
        </DialogContent>
      ) : (
        <Box className={classes.loader}>
          <CircularProgress aria-label='loading' />
        </Box>
      )}
      <DialogActions>
        <Button
          data-testid='close-dot-com-dialog-button'
          color='secondary'
          variant='outlined'
          onClick={closeModal}>
          {CLOSE}
        </Button>
        <AddToCartButton
          handleClick={handleDotComItemsAddToCart}
          disabledBool={
            updateDotComCartLoading ||
            Object.values(dotComCart.items).filter((item) => item.isSelected).length === 0
          }
          label={
            Object.values(dotComCart.items).filter((item) => item.isSelected).length > 0
              ? `${ADD_TO_CART} (${
                  Object.values(dotComCart.items).filter((item) => item.isSelected).length
                })`
              : null
          }
          loading={updateDotComCartLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    '& > div:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.spacing(2.5),
    },
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectAll: {
    display: 'block',
    paddingLeft: theme.spacing(2.5),
  },
  cartError: {
    color: theme.palette.error.main,
  },
}));

DotComCartModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default DotComCartModal;
