const translations = {
  ACCESS_CODES: {
    stringKey: 'accessCodes',
    primaryValue: 'Access Codes',
    description: 'header for the Member Offers table',
  },
  EXIT: {
    description: 'label on the Member Offers Page for "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  PROMO_CODES: {
    stringKey: 'promoCodes',
    primaryValue: 'Promo Codes',
    description: 'header for the Member Offers table',
  },
  PROMO_NAME: {
    stringKey: 'promoName',
    primaryValue: 'Promo Name',
    description: 'header for the Member Offers table',
  },
  PROMO_CODE: {
    stringKey: 'promoCode',
    primaryValue: 'Promo Code',
    description: 'header for the Member Offers table',
  },
  MEMBER_OFFERS: {
    stringKey: 'memberOffers',
    primaryValue: 'Member Offers',
    description: 'header for the Member Offers table',
  },
  NO_OFFERS_AVAILABLE: {
    stringKey: 'noOffers',
    primaryValue: 'Sorry, no offers available at this time.',
    description: 'message to display when zero offers are returned',
  },
  INVITES_ERROR: {
    stringKey: 'invitesError',
    primaryValue: 'Member Access Invites Error',
    description: 'error message for Member Access Invites',
  },
  OFFER_TYPE: {
    stringKey: 'offerType',
    primaryValue: 'Offer Type',
    description: 'type of promotional offer available',
  },
  STATUS: {
    stringKey: 'status',
    primaryValue: 'Status',
    description: 'status of promotional offer',
  },
  PRODUCT_NAME: {
    stringKey: 'productName',
    primaryValue: 'Product Name',
    description: 'product name',
  },
  COLOR_STYLE: {
    stringKey: 'colorStyle',
    primaryValue: 'Color/Style',
    description: 'The color and style code for a product',
  },
  START_DATE: {
    stringKey: 'startDate',
    primaryValue: 'Start Date',
    description: 'start date of promotional offer',
  },
  END_DATE: {
    stringKey: 'endDate',
    primaryValue: 'End Date',
    description: 'end date of promotional offer',
  },
  MAX_USES: {
    stringKey: 'maxUses',
    primaryValue: 'Max Uses',
    description: 'max uses of promotional offer',
  },
  ORDER_NUMBER: {
    stringKey: 'orderNumber',
    primaryValue: 'Order Number',
    description: 'order number associated with redeemed code',
  },
  UNLIMITED: {
    stringKey: 'unlimited',
    primaryValue: 'Unlimited',
    description: 'the number of times a promotional offers can be used is unlimited',
  },
  LANGUAGE_ERROR: {
    stringKey: 'languageError',
    primaryValue: 'Error getting language data for marketplace:',
    description: 'error message to show when language data could not be found',
  },
};

export default translations;
