import React, { createContext, useReducer } from 'react';
import { snackReducer, snackInitialState } from '../reducers/snackReducer';

export const SnackContext = createContext([snackInitialState, () => {}]);
const { Provider } = SnackContext;

/**
 * Provider for snacks
 *
 * @param {Object} props – React props
 */
export const SnackProvider = ({ children }) => {
  const [state, reducer] = useReducer(snackReducer, snackInitialState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};
