export const actionTypes = {
  SELECT_SKU: 'SELECT_SKU',
  UPDATE_QUANTITY: 'UPDATE_QUANTITY',
  SET_PRODUCT_INFO: 'SET_PRODUCT_INFO',
  SET_PUBLISHED_CONTENT: 'SET_PUBLISHED_CONTENT',
  SET_PRODUCT_LABEL: 'SET_PRODUCT_LABEL',
  SET_THREAD_ID: 'SET_THREAD_ID',
  SET_RELATED_PRODUCTS: 'SET_RELATED_PRODUCTS',
  SET_STYLE_CODE: 'SET_STYLE_CODE',
  CLEAR_QUANTITY_AND_SKU: 'CLEAR_QUANTITY_AND_SKU',
  SET_COLORWAY_LOADING: 'SET_COLORWAY_LOADING',
  SET_IS_NIKE_BY_YOU: 'SET_IS_NIKE_BY_YOU',
  SET_NBY_PRIMARY_IMAGE: 'SET_NBY_PRIMARY_IMAGE',
  SET_IS_GIFT_CARD: 'SET_IS_GIFT_CARD',
  SHOW_LOST_DEMAND_INVENTORY_TYPES: 'SHOW_LOST_DEMAND_INVENTORY_TYPES',
  SET_IS_MEMBER_ACCESS_ITEM: 'SET_IS_MEMBER_ACCESS_ITEM',
  SET_IS_EXCLUSIVE_ACCESS_ITEM: 'SET_IS_EXCLUSIVE_ACCESS_ITEM',
  SET_ROLLUP_KEY: 'SET_ROLLUP_KEY',
  SET_GC_MESSAGE: 'SET_GC_MESSAGE',
  SET_GC_AMOUNT: 'SET_GC_AMOUNT',
  SET_GC_IMAGES: 'SET_GC_IMAGES',
  SET_VALIDATED_VAS: 'SET_VALIDATED_VAS',
  SET_GC_SKU: 'SET_GC_SKU',
  SET_GIFT_CARD_RECIPIENT_INFO: 'SET_GIFT_CARD_RECIPIENT_INFO',
};

export const actions = {
  selectSku:
    /**
     * Set the sku object that the athlete has selected.
     * @param {object} item object containing information about the selected sku.
     */
    (sku) => ({
      type: actionTypes.SELECT_SKU,
      sku,
    }),

  updateQuantity:
    /**
     * Set the quantity of objects to purchase.
     * @param {number} quantity number of objects to purchase.
     */
    (quantity) => ({
      type: actionTypes.UPDATE_QUANTITY,
      quantity,
    }),

  setProductInfo:
    /**
     * Set the product info that will be used for the PDP.
     * @param {object} productInfo product information retrieved from the product feed service.
     */
    (productInfo) => ({
      type: actionTypes.SET_PRODUCT_INFO,
      productInfo,
    }),

  setPublishedContent:
    /**
     * Set published content that will be used for the PDP.
     * @param {object} publishedContent published content retrieved from the product feed service.
     */
    (publishedContent) => ({
      type: actionTypes.SET_PUBLISHED_CONTENT,
      publishedContent,
    }),

  setProductLabel:
    /**
     * Set the product label that will be used for the PDP.
     * @param {string} productLabel product label from the product feed/rollupthreads service.
     */
    (productLabel) => ({
      type: actionTypes.SET_PRODUCT_LABEL,
      productLabel,
    }),

  setThreadId:
    /**
     * Set the thread of of the product
     * @param {number} quantity number of objects to purchase.
     */
    (threadId) => {
      return {
        type: actionTypes.SET_THREAD_ID,
        threadId,
      };
    },

  setStyleCode:
    /**
     * Set the styleCode of of the product
     * @param {number} quantity number of objects to purchase.
     */
    (styleCode) => {
      return {
        type: actionTypes.SET_STYLE_CODE,
        styleCode,
      };
    },

  setRelatedProducts:
    /**
     * Set the related product info.
     * @param {number} quantity number of objects to purchase.
     */
    (relatedProducts) => ({
      type: actionTypes.SET_RELATED_PRODUCTS,
      relatedProducts,
    }),

  clearQuantityAndSku:
    /**
     * Resets quantity and selected sku.
     */
    () => ({
      type: actionTypes.CLEAR_QUANTITY_AND_SKU,
    }),

  setColorwayLoading:
    /**
     * Set the boolean for colorway loading.
     * @param {boolean} isColorwaysLoading - boolean to represent the spinner
     */
    (isColorwaysLoading) => ({
      type: actionTypes.SET_COLORWAY_LOADING,
      isColorwaysLoading,
    }),

  setIsNikeByYou:
    /**
     * Set the boolean isNikeByYou.
     * @param {boolean} isNikeByYou - boolean to set if the product is NBY
     */
    (isNikeByYou) => ({
      type: actionTypes.SET_IS_NIKE_BY_YOU,
      isNikeByYou,
    }),

  setIsGiftCard:
    /**
     * Set the boolean isGiftCard.
     * @param {boolean} isGiftCard - boolean to set if the product is GiftCard
     */
    (isGiftCard) => ({
      type: actionTypes.SET_IS_GIFT_CARD,
      isGiftCard,
    }),

  setGiftCardImages:
    /**
     * Set the array with gift card images.
     * @param {object} giftCardImages- gift card details
     */
    (images) => ({
      type: actionTypes.SET_GC_IMAGES,
      images,
    }),

  setNBYPrimaryImage:
    /**
     Set the NBY primary Image.
     * @param {String} nbyPrimaryImage - NBY Image
     */
    (nbyPrimaryImage) => ({
      type: actionTypes.SET_NBY_PRIMARY_IMAGE,
      nbyPrimaryImage,
    }),

  setShowLostDemandInventoryTypes:
    /**
     * Sets the boolean showLostDemandInventoryTypes.
     * @param {boolean} showLostDemandInventoryOptions - boolean that determines the display of
     * lost demand inventory options
     */
    (showLostDemandInventoryTypes) => ({
      type: actionTypes.SHOW_LOST_DEMAND_INVENTORY_TYPES,
      showLostDemandInventoryTypes,
    }),

  setIsMemberAccessItem:
    /**
     * Set the boolean isMemberAccessItem.
     * @param {boolean} isMemberAccessItem - boolean to set if the product is a member access item
     */
    (isMemberAccessItem) => ({
      type: actionTypes.SET_IS_MEMBER_ACCESS_ITEM,
      isMemberAccessItem,
    }),

  setIsExclusiveAccessItem:
    /**
     * Sets the flag to indicate whether is item is considered exclusive access only.
     * @param {boolean} isExclusiveAccessItem - indicates if item is an exclusive access item.
     */
    (isExclusiveAccessItem) => ({
      type: actionTypes.SET_IS_EXCLUSIVE_ACCESS_ITEM,
      isExclusiveAccessItem,
    }),

  setRollupKey:
    /**
     * Set the string rollupKey
     * @param {string} rollupKey - used to query for related products
     */
    (rollupKey) => ({
      type: actionTypes.SET_ROLLUP_KEY,
      rollupKey,
    }),

  setGiftAmount:
    /**
     * Set the giftcard amount
     * @param {number} amount - gift card amount
     */
    (amount) => ({
      type: actionTypes.SET_GC_AMOUNT,
      amount,
    }),

  setGiftMessage:
    /**
     * Set the giftcard message
     * @param {String} message - gift card message
     */
    (message) => ({
      type: actionTypes.SET_GC_MESSAGE,
      message,
    }),

  setValidatedVAS:
    /**
     * Set the validated VAS data
     * @param {object} vasInfo - validated vas info
     */
    (vasInfo) => ({
      type: actionTypes.SET_VALIDATED_VAS,
      vasInfo,
    }),

  setGiftCardSku:
    /**
     * Updates the product info object with gift card sku information
     * @param {object} skuInfo - gift card sku info
     */
    (skuInfo) => ({
      type: actionTypes.SET_GC_SKU,
      skuInfo,
    }),

  setGiftCardRecipientInfo:
    /**
     * replaces the giftCardRecipientDetails state with updated data
     * @param {object} giftCardRecipientDetails - gift card sku info
     */
    (giftCardRecipientDetails) => ({
      type: actionTypes.SET_GIFT_CARD_RECIPIENT_INFO,
      giftCardRecipientDetails,
    }),
};

export default actions;
