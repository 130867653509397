export const actionTypes = {
  SET_DOT_COM_CART: 'SET_DOT_COM_CART',
  SET_STAGED_ITEMS: 'SET_STAGED_ITEMS',
  SET_SIZE_SKU_IDS: 'SET_SIZE_SKU_IDS',
  SELECT_ITEM: 'SELECT_ITEM',
  UNSELECT_ITEM: 'UNSELECT_ITEM',
  SELECT_ITEM_SIZE: 'SELECT_ITEM_SIZE',
  UPDATE_DOTCOM_CART: 'UPDATE_DOTCOM_CART',
  SET_DOTCOM_MODAL_ERROR: 'SET_DOTCOM_MODAL_ERROR',
};

export const actions = {
  setDotComCart:
    /**
     * Set the dot com cart data per the response to the cartViews service
     * @param {array} cartItems
     */
    (cartData) => ({
      type: actionTypes.SET_DOT_COM_CART,
      cartData,
    }),

  setSizeSkuIds:
    /**
     * Set the sku id for all alternative sizes for a given product in the dot com cart.
     * @param {string} id - id for cart item whose sku data updated
     * @param {object} sizeData - object of size data, keys are a sku's GTIN
     * @param {string} sizeData.skuId - skuId for a given size on the product
     * @param {string} sizeData.localizedSize - localized size string for a given skuId
     */
    (id, sizeData) => ({
      type: actionTypes.SET_SIZE_SKU_IDS,
      id,
      sizeData,
    }),

  selectItem:
    /**
     * Mark a dot com cart item as selected in the UI
     * @param {string} id - id for the cart item
     */
    (id) => ({
      type: actionTypes.SELECT_ITEM,
      id,
    }),

  selectItemSize:
    /**
     * Mark a dot com cart item as selected in the UI
     * @param {string} id - id for the cart item
     */
    (id, skuId) => ({
      type: actionTypes.SELECT_ITEM_SIZE,
      id,
      skuId,
    }),

  unselectItem:
    /**
     * Mark a dot com cart item as UNselected in the UI
     * @param {string} id - id for the cart item
     */
    (id) => ({
      type: actionTypes.UNSELECT_ITEM,
      id,
    }),

  setStagedItems:
    /**
     * Set the cart items staged for migration from dot com cart to omobo cart
     * @param {object} items
     */
    (items) => ({
      type: actionTypes.SET_STAGED_ITEMS,
      items,
    }),

  updateDotComCart:
    /**
     * For removing items from dotcom context when successfully removed from dotcom cart
     * @param {array} cartItemIds
     */
    (cartItemIds) => ({
      type: actionTypes.UPDATE_DOTCOM_CART,
      cartItemIds,
    }),

  setDotComModalError:
    /**
     * For setting error message when mutating ocobo cart in dot com modal is unsuccessful
     * @param {string} errorMessage
     */
    (errorMessage) => ({
      type: actionTypes.SET_DOTCOM_MODAL_ERROR,
      errorMessage,
    }),
};

export default actions;
