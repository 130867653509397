import React, { useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackContext } from '../../../store/contexts/snackContext';
import snackActions from '../../../store/actions/snackActions';

/**
 * Component to show the response notifications
 * Basically a trimmed down version of the ResponseSnackBar from cs-order-management
 * Loading options have been removed to live in their own loading component (for now)
 *
 * To control, use the useSnack() hook
 */
export default function SnackBar() {
  const [snackState, snackDispatch] = useContext(SnackContext);
  const { text, status, isOpen, networkError } = snackState;
  const { clearSnack, setOpen } = snackActions;

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    snackDispatch(clearSnack());
  };

  if (isOpen)
    return (
      <Snackbar
        open={isOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={status === 'error' ? null : 10000}
        onClose={handleClose}
        data-testid='snackbar'>
        <Alert onClose={handleClose} severity={status}>
          {text}
          {networkError && status === 'error' && (
            <>
              <br />
              {networkError}
            </>
          )}
        </Alert>
      </Snackbar>
    );
  else return null;
}
