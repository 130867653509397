import gql from 'graphql-tag';

const CART_VIEW_QUERY = gql`
  query getCartView($input: CartViewInput!, $timeout: Int!) {
    getCartView(input: $input, timeout: $timeout) {
      id
      resourceType
      status
      links {
        self {
          ref
        }
      }
      eta
      error {
        httpStatus
        message
        code
        errors {
          field
          code
          message
          crcCode
        }
      }
      response {
        cartId
        country
        brand
        channel
        currency
        locale
        storeId
        items {
          id
          skuId
          productId
          offer
          quantity
          itemCosts {
            priceInfo {
              price
              fullPrice
              discounted
              employeePrice
              subtotal
              discount
              valueAddedServices
              taxTotal
              total
            }
            taxes {
              type
              rate
              total
            }
            promotionDiscounts {
              code
              amount
              id
              displayName
            }
          }
          sizeDetails {
            resourceType
            gtin
            nikeSize
            localizedSize
            localizedSizePrefix
          }
          productDetails {
            resourceType
            id
            pid
            productType
            quantityLimit
            styleColor
            styleType
            merchGroup
            brand
            productRollup {
              type
              key
            }
            exclusiveAccess
            sportTags
            publishType
          }
          contentDetails {
            resourceType
            productId
            title
            fullTitle
            subtitle
            slug
            colorDescription
          }
          imagesDetails {
            resourceType
            images
          }
          valueAddedServices {
            id
            instruction {
              id
              type
            }
            valueAddedServiceCosts {
              priceInfo {
                price
                subtotal
                discount
                taxTotal
                total
              }
              taxes {
                type
                rate
                total
              }
            }
            valueAddedServiceDetails {
              resourceType
              id
              status
              type
              pid
              displayName
              productCode
            }
            designViewDetails {
              resourceType
              instructionId
              productId
              pathName
              customText {
                label
                value
              }
              legacySize {
                label
                sizeType
              }
            }
            giftMessage {
              resourceType
              country
              locale
              language
              giftMessage
            }
          }
          giftCard {
            amount
          }
          recipient {
            firstName
            lastName
            altFirstName
            altLastName
            middleName
            middleInitial
            givenName
          }
          shippingAddress {
            address1
            address2
            address3
            city
            state
            postalCode
            country
            county
            email
          }
          itemData {
            url
          }
          fulfillmentDetails {
            type
          }
          alternativeSizes {
            resourceType
            gtin
            nikeSize
            localizedSize
            localizedSizePrefix
          }
        }
        promotionCodes {
          displayName
          discountTotal
          code
          status
          reasons {
            code
            value
          }
        }
        totals {
          items {
            total
            details {
              price
              discount
            }
          }
          valueAddedServices {
            total
            details {
              price
              discount
            }
          }
          fulfillment {
            total
            details {
              price
              discount
            }
          }
          taxes {
            total
            details {
              items {
                tax
                type
              }
              shipping {
                tax
                type
              }
              valueAddedServices {
                tax
                type
              }
            }
          }
          total
        }
      }
      warnings {
        field
        code
        message
      }
    }
  }
`;

export default CART_VIEW_QUERY;
