import { actionTypes } from '../actions/snackActions';

export const snackInitialState = {
  isOpen: false,
  text: '',
  networkError: '',
  status: '',
  type: '',
};

/**
 * The reducer for the snack context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const snackReducer = (state = snackInitialState, action = {}) => {
  switch (action.type) {
    /*
    show snack with specified status and text, turns off loading if set.
    */
    case actionTypes.SET_SNACK: {
      if (action.text && action.status) {
        return {
          ...snackInitialState,
          text: action.text,
          status: action.status,
          networkError: state.networkError,
          isOpen: true,
        };
      } else return state;
    }

    /*
    clears set snack and loading, if set.
    */
    case actionTypes.CLEAR_SNACK: {
      return {
        ...snackInitialState,
      };
    }

    /*
    sets open to boolean
    */
    case actionTypes.SET_OPEN: {
      return {
        ...state,
        isOpen: Boolean(action.isOpen),
      };
    }

    /*
    appends text to an error
    */
    case actionTypes.ADD_NETWORK_ERROR: {
      return {
        ...snackInitialState,
        text: state.text,
        status: 'error',
        networkError: action.text,
        isOpen: true,
      };
    }

    default: {
      return state;
    }
  }
};
