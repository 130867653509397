import { useContext } from 'react';
import { useHistory } from 'react-router';
import { SearchContext } from '../store/contexts/searchContext';
import { actions as searchActions } from '../store/actions/searchActions';
import EUCountries from '../constants/EUCountries.const';
import { Regions } from '../constants/regions.const';
// TODO: replace with another package, as URLSearchParams API does not support our formatting
import queryString from 'query-string';

const EUCountriesAbbreviations = EUCountries.map((c) => c.abbreviation);

/**
 * A custom hook to streamline the use of the OCOBO search query structure.
 * Import and use the function as regular hook, and deconstruct necessary actions:
 * const { setSnack, setError } = useSnacks();
 */
export default function useSearchQuery() {
  const [searchState, searchDispatch] = useContext(SearchContext);
  const { setQuery } = searchActions;
  const history = useHistory();

  /*
   * provides the order we'd like the query params to appear in for
   * ease of readability.
   */
  const keyOrder = ['key', 'attr', 'mp', 'cartId', 'caseId'];

  /**
   * Function to extract the query from the current path
   *
   * @param {String} path - uri path with search query
   */
  const paramsFromPath = (path) => {
    return queryString.parse(path, { arrayFormat: 'comma' });
  };

  /**
   * Generates a string of the current url path, with the current saved
   * url values (cardId, caseId, search key, mp. etc.)
   * Optionally, replacements for these values can be passed through
   * the 'additions' object which should be structured as a dict.
   *
   * E.x. additions = { cartId: '1234, mp: 'CN' }
   *
   * @param {object} additions - OPTIONAL dict object of url additions
   * @returns {string} - current url path
   */
  const generatePath = (additions) => {
    let route = window.location.pathname;
    const { search } = history?.location;
    let query = paramsFromPath(search);

    // remove process.env.PUBLIC_URL to avoid repeating the path
    route = route.replace(process.env.PUBLIC_URL, '');

    if (!additions) {
      return history.location.search
        ? `${route === '/' ? '' : route}${history.location.search}`
        : `${route === '/' ? '' : route}`;
    } else {
      Object.keys(additions).forEach((key) => {
        query[key] = additions[key];
      });

      // Default mp to CN when in china, if no MP, set to US
      query['mp'] = process.env.PUBLIC_URL === '/portlets/shop' ? 'CN' : query['mp'] || 'US';

      const queryPath = queryString.stringify(query, {
        arrayFormat: 'comma',
        sort: (a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b),
      });

      return `${route}?${queryPath}`;
    }
  };

  /**
   * Updates search state with new searchObject from uri path and
   * returns the object.
   *
   * @param {String} path – search path from uri
   */
  const objectifyPath = (path) => {
    const newSearchObject = queryString.parse(path, { arrayFormat: 'comma' });
    searchDispatch(setQuery(newSearchObject));
    return newSearchObject;
  };

  /**
   * Returns url compatible string from stored query
   
   */
  const stringifyQuery = () => {
    const { query } = searchState;
    return queryString.stringify(query, {
      arrayFormat: 'comma',
      sort: (a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b),
    });
  };

  /**
   * returns true if app is deployed in China or an athlete is making a China cart
   *
   * @returns boolean
   */
  const isChina = () => {
    const { search } = history?.location;
    let query = paramsFromPath(search);
    if (process.env.PUBLIC_URL === '/portlets/shop' || query.mp === 'CN') {
      return true;
    }
    return false;
  };

  const getRegion = () => {
    const { search } = history?.location;
    let { mp } = paramsFromPath(search);

    if (!mp) {
      return Regions.NA;
    }

    // country is itself a region
    if (mp.toUpperCase() in Regions) {
      return Regions[mp];
    }
    // country is part of EMEA region
    if (EUCountriesAbbreviations.includes(mp)) {
      return Regions.EMEA;
    }
    // default to NA
    return Regions.NA;
  };
  /**
   * returns true if app is deployed in China
   *
   * @returns boolean
   */
  const inChinaRegion = () => {
    if (process.env.PUBLIC_URL === '/portlets/shop') {
      return true;
    }
    return false;
  };

  return {
    paramsFromPath,
    objectifyPath,
    stringifyQuery,
    getRegion,
    generatePath,
    isChina,
    inChinaRegion,
  };
}
