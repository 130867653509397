import { actionTypes } from '../actions/searchActions';
import SearchEventEnum from '../../constants/search.const';

// grab marketplace from url if it exists
const searchParams = new window.URLSearchParams(window.location.search);
const urlMp = searchParams.get('mp');

export const searchInitialState = {
  channelId: 'd9a5bc42-4b9c-4976-858a-f159cf99c647',
  leftNav: {},
  objects: [],
  anchor: 0,
  query: null,
  sortState: '',
  event: SearchEventEnum.NO_SEARCH,
  areFiltersLoading: false,
  selectedCountry: urlMp || (process.env.PUBLIC_URL === '/portlets/shop' ? 'CN' : 'US'),
  selectedLanguage: process.env.PUBLIC_URL === '/portlets/shop' ? 'zh-Hans' : 'en',
  languageArray: [],
  displayModal: '',
  cartCurrency: '',
  locale: '',
};

/**
 * The reducer for the search context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */

export const searchReducer = (state = searchInitialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_QUERY: {
      return {
        ...state,
        query: action.query,
      };
    }
    case actionTypes.SET_ROLLUP: {
      const hasResults = action.objects.length > 0;

      return {
        ...state,
        objects: action.objects,
        pages: action.pages,
        event: hasResults ? SearchEventEnum.RESULTS : SearchEventEnum.NO_RESULTS,
      };
    }
    case actionTypes.UPDATE_LEFT_NAV: {
      return {
        ...state,
        areFiltersLoading: false,
        leftNav: action.leftNav,
      };
    }
    case actionTypes.SET_SORT: {
      return {
        ...state,
        sortState: action.sortState,
      };
    }
    case actionTypes.SET_ANCHOR: {
      return {
        ...state,
        anchor: action.anchor,
      };
    }
    case actionTypes.SET_FILTERS_LOADING: {
      return {
        ...state,
        areFiltersLoading: true,
      };
    }
    case actionTypes.SET_COUNTRY: {
      return {
        ...searchInitialState,
        selectedCountry: action.selectedCountry,
      };
    }
    case actionTypes.SET_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.selectedLanguage,
        locale: action.locale,
      };
    }
    case actionTypes.SET_DISPLAY_MODAL: {
      return {
        ...searchInitialState,
        languageArray: state.languageArray,
        selectedLanguage: state.selectedLanguage,
        displayModal: action.displayModal,
        selectedCountry: action.selectedCountry,
      };
    }
    case actionTypes.SET_CART_CURRENCY: {
      return {
        ...state,
        cartCurrency: action.cartCurrency,
      };
    }
    case actionTypes.RESET_SEARCH: {
      return {
        ...searchInitialState,
        languageArray: state.languageArray,
        selectedLanguage: state.selectedLanguage,
        selectedCountry: state.selectedCountry,
      };
    }
    case actionTypes.SET_LANGUAGE_ARRAY: {
      return {
        ...state,
        languageArray: action.languageArray,
      };
    }
    default: {
      return state;
    }
  }
};
