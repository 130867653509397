import { actionTypes } from '../actions/noboActions';
import { defaultState } from '../contexts/noboTokenContext';

/**
 * The reducer for the admin context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const noboReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_NOBO_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }
    default: {
      return state;
    }
  }
};
