/* okta */
import { OktaAuth } from '@okta/okta-auth-js';
import decodeToken from 'jwt-decode';

/* Local */
import config from './config';
import { SessionStorage, OrderExchangeService } from '../constants/consumerDelegatedToken.const';

const authClient = new OktaAuth(config.okta);
const tokenManager = authClient.tokenManager;
const Access_Token = 'accessToken';

/*
returns admin token when conditions are satisfied, retries for 5 times before
throwing an error
*/
export const getConsumerDelegatedToken = async (caseId) => {
  let error;
  try {
    return await fetchConsumerDelegatedToken(caseId);
  } catch (err) {
    error = err;
  }
  throw error;
};

// fetches consumer delegated token
export const fetchConsumerDelegatedToken = async (caseId) => {
  /*
  we are renewing the okta token every time when consumer delegated token is requested as
  expiration time of consumer delegated is based on expiration time of okta token
  */
  const oktaToken = await tokenManager.renew(Access_Token);
  if (oktaToken && isActive(oktaToken)) {
    /*
      if we have the case id in local storage and the token associated with it is active then
      return access token - which is a consumer delegated token
    */
    const noboInfo = sessionStorage.getItem(SessionStorage.CaseHeader);
    const tokenByCaseId =
      noboInfo && JSON.parse(noboInfo)?.[`cs_case_${caseId}`]?.token?.access_token;

    if (tokenByCaseId) {
      return tokenByCaseId;
    }
    /*
          handles the below scenario:
          No case info in the nike.cs.obo.ocobo so far
    */
    const accessToken = `Bearer ${oktaToken?.accessToken}`;
    const tokenExchangeResponse = await callTokenExchangeService(accessToken, caseId);
    const consumerDelegatedAccessToken = tokenExchangeResponse?.token?.access_token;
    if (consumerDelegatedAccessToken) {
      sessionStorage.setItem(
        SessionStorage.CaseHeader,
        JSON.stringify(
          {
            [`cs_case_${caseId}`]: {
              token: {
                access_token: consumerDelegatedAccessToken,
              },
              case: {
                caseId: tokenExchangeResponse?.case?.caseId || '',
                visitorId: tokenExchangeResponse?.case?.visitorId || '',
                userType: tokenExchangeResponse?.case?.userType || '',
                profileId: tokenExchangeResponse?.case?.profileId || '',
              },
            },
          },
          null,
          2
        )
      );
      return consumerDelegatedAccessToken;
    }
  }

  /*
   * throw error in all other scenarios.
   * throwing 500 would display the error message "Token expired. Please go back and start again."
   */
  throw new Error('500');
};

// checks if the passed token is active.
export const isActive = (token) => {
  return !tokenManager.hasExpired(token);
};

/**
 *
 * @param {string} accessToken - okta access token
 * @param {string} caseId - unique id for a case
 * @returns {string} - consumer delegated token
 */
export const callTokenExchangeService = async (accessToken, caseId) => {
  try {
    // calling token exchange service (gagarin)
    const response = await fetch(`${config.tokenExchange.tokenExchangeUrl}/${caseId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'authorization': accessToken,
        'content-type': 'application/json',
      },
      method: 'post',
    });
    // throw error if we don't get a success response from token exchange service
    if (response.status !== OrderExchangeService.Success_Status_Code) {
      const tokenExchangeErrorResponse = await response?.json();
      if (response?.status === OrderExchangeService.Error_Case_Not_Found) {
        // throwing 404 would display the error message "Case record not found"
        throw new Error('404');
      }
      throw new Error(`${response?.status} - ${tokenExchangeErrorResponse?.message}`);
    }
    const tokenExchangeJsonResponse = await response.json();
    return tokenExchangeJsonResponse;
  } catch (error) {
    throw new Error(`${error?.message}`);
  }
};

/**
 * Function to check if the token passed is valid or not
 * Token is valid if the token expiration date is in the future
 * @param {string} consumerDelegatedToken
 * @returns {boolean} true if the token is valid else false
 */

export const isConsumerDelegatedTokenValid = (consumerDelegatedToken) => {
  const { exp } = decodeToken(consumerDelegatedToken);
  if (exp < (new Date().getTime() + 1) / 1000) {
    return false;
  }
  return true;
};
