export const Regions = {
  NA: 'NA',
  EMEA: 'EMEA',
  CN: 'CN',
  KR: 'KR',
  JP: 'JP',
};

export const NoDigitalGiftCardRegions = [Regions.EMEA];
export const NoPhysicalGiftCardRegions = [Regions.JP];
