import { useContext } from 'react';
import { SnackContext } from '../store/contexts/snackContext';
import { actions as snackActions } from '../store/actions/snackActions';
import { SnackStatuses } from '../constants/snack.const';

/**
 * A custom hook to streamline the use of the responsive snackbar.
 * Import and use the function as regular hook, and deconstruct necessary actions:
 * const { setSnack, setError } = useSnacks();
 */
export default function useSnacks() {
  const [, snackDispatch] = useContext(SnackContext);
  // sorry for the weird renaming, but it makes sense to pass the functions on starting with 'set'
  const { setSnack: startSnack, addNetworkError } = snackActions;

  /**
   * Passed on function to show an error in the snackbar, also ends loading.
   *
   * @param {String} message – the text of the snack
   */
  const setError = (message) => {
    snackDispatch(startSnack(message, SnackStatuses.ERROR));
  };

  /**
   * Passed on function to add a network error to append to normal errors.
   *
   * @param {String} message – the text of the snack
   */
  const setNetworkError = (message) => {
    snackDispatch(addNetworkError(message));
  };

  /**
   * Passed on function to show a message in the snackbar, also ends loading.
   * Can be multiple colors, see https://material-ui.com/components/snackbars/ for examples.
   *
   * @param {String} message – the text of the snack
   * @param {String} [color] – optional, the color of the snack. default is green.
   */
  const setSnack = (message, color = 'green') => {
    const status = ((color) => {
      switch (color) {
        case 'green':
          return SnackStatuses.SUCCESS;
        case 'blue':
          return SnackStatuses.INFO;
        case 'yellow':
          return SnackStatuses.WARNING;
        default:
          return SnackStatuses.SUCCESS;
      }
    })(color);
    snackDispatch(startSnack(message, status));
  };

  return { setError, setSnack, setNetworkError };
}
