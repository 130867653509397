export const actionTypes = {
  SET_QUERY: 'SET_QUERY',
  SET_ROLLUP: 'SET_ROLLUP',
  SET_FILTERS: 'SET_FILTERS',
  SET_ANCHOR: 'SET_ANCHOR',
  UPDATE_LEFT_NAV: 'UPDATE_LEFT_NAV',
  SET_FILTERS_LOADING: 'SET_FILTERS_LOADING',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_DISPLAY_MODAL: 'SET_DISPLAY_MODAL',
  SET_SORT: 'SET_SORT',
  SET_CART_CURRENCY: 'SET_CART_CURRENCY',
  SET_LANGUAGE_ARRAY: 'SET_LANGUAGE_ARRAY',
};

export const actions = {
  setQuery:
    /**
     * This action sets the most recent query
     *
     * @param {Object} query – the query object
     */
    (query) => ({
      type: actionTypes.SET_QUERY,
      query,
    }),

  setRollup:
    /**
     * This action sets the returned results from the rollup call
     *
     * @param {Array} objects – the objects to set in state
     * @param {Object} pages - the pages to set in state
     */
    ({ pages, objects }) => ({
      type: actionTypes.SET_ROLLUP,
      pages,
      objects,
    }),

  updateLeftNav:
    /**
     * This action sets the returned filters and other information used for the lefthand navigation
     * from the recommendedNav call.  Also sets areFiltersLoading to false.
     *
     * @param {Array} leftNav – the returned information to set in state
     */
    (leftNav) => ({
      type: actionTypes.UPDATE_LEFT_NAV,
      leftNav,
    }),

  setSort:
    /**
     * This action sets the sort on search results
     *
     * @param {String} sortState – the sort value to be set in state
     */
    (sortState) => ({
      type: actionTypes.SET_SORT,
      sortState,
    }),

  setAnchor:
    /**
     * This action sets the anchor on search results
     *
     * @param {Number} anchor – the anchor number to be set in state
     */
    (anchor) => ({
      type: actionTypes.SET_ANCHOR,
      anchor,
    }),

  setCountry:
    /**
     * This action sets the country for searches
     *
     * @param {String} country – the two character code for a country
     */
    (selectedCountry) => ({
      type: actionTypes.SET_COUNTRY,
      selectedCountry,
    }),

  setLanguage:
    /**
     * This action sets the country for searches
     *
     * @param {String} language – the language to show results in
     */
    (selectedLanguage, locale) => ({
      type: actionTypes.SET_LANGUAGE,
      selectedLanguage,
      locale,
    }),

  setCartCurrency:
    /**
     * This action sets the country for currency in the cart
     *
     * @param {String} cartCurrency – the currency to show in the cart
     */
    (cartCurrency) => ({
      type: actionTypes.SET_CART_CURRENCY,
      cartCurrency,
    }),

  setDisplayModal:
    /**
     * This action changes the modal value and the selectedCountry value
     *
     * @param {String} displayModal – the string that determines if the modal should display and
     * what should happen when the modal is closed
     * @param {String} selectedCountry – the two character code for a country
     */
    (displayModal, selectedCountry) => ({
      type: actionTypes.SET_DISPLAY_MODAL,
      displayModal,
      selectedCountry,
    }),

  setFiltersLoading:
    /**
     * This action sets the areFiltersLoading boolean to true, will be set false
     * in the updateLeftNav action.
     */
    () => ({
      type: actionTypes.SET_FILTERS_LOADING,
    }),

  resetSearch:
    /**
     * This action sets the search context back to its initial state.
     */
    () => ({
      type: actionTypes.RESET_SEARCH,
    }),

  setLanguageArray:
    /**
     * This action sets the list of countries and their localization data
     *
     * @param {Array} languageArray – objects with localization info
     */
    (languageArray) => ({
      type: actionTypes.SET_LANGUAGE_ARRAY,
      languageArray,
    }),
};

export default actions;
