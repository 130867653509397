const EUCountries = [
  {
    name: 'Austria',
    label: 'Austria',
    abbreviation: 'AT',
    alpha3Code: 'AUT',
  },
  {
    name: 'Belgium',
    label: 'Belgium',
    abbreviation: 'BE',
    alpha3Code: 'BEL',
  },
  {
    name: 'Czech Republic',
    label: 'Czech Republic',
    abbreviation: 'CZ',
    alpha3Code: 'CZE',
  },
  {
    name: 'Denmark',
    label: 'Denmark',
    abbreviation: 'DK',
    alpha3Code: 'DNK',
  },
  {
    name: 'Finland',
    label: 'Finland',
    abbreviation: 'FI',
    alpha3Code: 'FIN',
  },
  {
    name: 'France',
    label: 'France',
    abbreviation: 'FR',
    alpha3Code: 'FRA',
  },
  {
    name: 'Germany',
    label: 'Germany',
    abbreviation: 'DE',
    alpha3Code: 'DEU',
  },
  {
    name: 'Greece',
    label: 'Greece',
    abbreviation: 'GR',
    alpha3Code: 'GRC',
  },
  {
    name: 'Hungary',
    label: 'Hungary',
    abbreviation: 'HU',
    alpha3Code: 'HUN',
  },
  {
    name: 'Ireland',
    label: 'Ireland',
    abbreviation: 'IE',
    alpha3Code: 'IRL',
  },
  {
    name: 'Italy',
    label: 'Italy',
    abbreviation: 'IT',
    alpha3Code: 'ITA',
  },
  {
    name: 'Korea',
    label: 'Korea',
    abbreviation: 'KR',
    alpha3Code: 'KOR',
  },
  {
    name: 'Luxembourg',
    label: 'Luxembourg',
    abbreviation: 'LU',
    alpha3Code: 'LUX',
  },
  {
    name: 'Netherlands',
    label: 'Netherlands',
    abbreviation: 'NL',
    alpha3Code: 'NLD',
  },
  {
    name: 'Poland',
    label: 'Poland',
    abbreviation: 'PL',
    alpha3Code: 'POL',
  },
  {
    name: 'Portugal',
    label: 'Portugal',
    abbreviation: 'PT',
    alpha3Code: 'PRT',
  },
  {
    name: 'Slovenia',
    label: 'Slovenia',
    abbreviation: 'SI',
    alpha3Code: 'SVN',
  },
  {
    name: 'Spain',
    label: 'Spain',
    abbreviation: 'ES',
    alpha3Code: 'ESP',
  },
  {
    name: 'Sweden',
    label: 'Sweden',
    abbreviation: 'SE',
    alpha3Code: 'SWE',
  },
  {
    name: 'United Kingdom',
    label: 'United Kingdom',
    abbreviation: 'GB',
    alpha3Code: 'GBR',
  },
];

export default EUCountries;
