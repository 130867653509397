const translations = {
  ARIA_MAIN_APP: {
    stringKey: 'ariaMainApp',
    primaryValue: 'main application',
    description: 'aria label for the main application landmark',
  },
  LANGUAGE_ERROR: {
    stringKey: 'languageError',
    primaryValue: 'Error getting language data for marketplace:',
    description: 'error message to show when language data could not be found',
  },
};

export default translations;
