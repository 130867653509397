// enum for gift card types
export const GiftCardTypes = {
  PHYSICAL: 'PHYSICAL',
  DIGITAL: 'DIGITAL',
};

export const GiftAmount = {
  US: {
    MIN_AMOUNT: '25',
    MAX_AMOUNT: '500',
    GIFT_AMOUNT_RANGE: ['$25', '$500'],
  },
  JP: {
    MIN_AMOUNT: '2500',
    MAX_AMOUNT: '50000',
    GIFT_AMOUNT_RANGE: ['¥2,500', '¥50,000'],
  },
  DK: {
    MIN_AMOUNT: '200',
    MAX_AMOUNT: '4000',
    GIFT_AMOUNT_RANGE: ['200 kr.', '4.000 kr.'],
  },
  PL: {
    MIN_AMOUNT: '100',
    MAX_AMOUNT: '2000',
    GIFT_AMOUNT_RANGE: ['100zł', '2000zł'],
  },
  SE: {
    MIN_AMOUNT: '200',
    MAX_AMOUNT: '5000',
    GIFT_AMOUNT_RANGE: ['200 kr', '5000 kr'],
  },
  EMEA: {
    MIN_AMOUNT: '25',
    MAX_AMOUNT: '500',
    GIFT_AMOUNT_RANGE: ['€25', '€500'],
  },
};

export const Currency = {
  US: '$',
};

export const RecipientDetailsDefaults = {
  firstName: '',
  lastName: '',
  email: '',
};

export const recipientErrorsDefault = {
  firstName: false,
  lastName: false,
  email: false,
};

export const PDPGiftCardTypes = {
  DIGITAL_GIFT_CARD: 'DIGITAL',
  PHYSICAL_GIFT_CARD: 'PHYSICAL',
};
