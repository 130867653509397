import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthService, Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import config from './utils/config';
import MemberOffers from './components/views/memberOffers';
import Providers from './providers';
import { SnackProvider } from './store/contexts/snackContext';
import './App.css';

const ProductDetailsPage = lazy(() => import('./components/views/productDetails'));
const Search = lazy(() => import('./components/views/search'));
/**
 * The main application component for CS Order Creation
 */
function App() {
  const authService = new AuthService(config.okta);

  /**
   * automatically sends an SCL request if:
   * - we're in a dev environment
   * - no case token is available
   * - a .env.development file exists and has information required for an SCL request
   * with this working the team won't have to run a postman request when developing
   */
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'local') {
      // using require here to avoid importing a package that usually won't be used
      const { default: decodeToken } = require('jwt-decode');
      authService.getAccessToken().then((token) => {
        const decodedToken = decodeToken(token);
        const {
          REACT_APP_USER_TYPE,
          REACT_APP_PROFILE_ID,
          REACT_APP_TIME_TO_LIVE,
          REACT_APP_CASE_ID,
          REACT_APP_ATHLETE_EMAIL,
        } = process.env;
        if (!decodedToken.cs_case_caseid && REACT_APP_ATHLETE_EMAIL) {
          fetch('https://scl.forge-prod.nikecloud.com/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userType: REACT_APP_USER_TYPE,
              profileId: REACT_APP_PROFILE_ID,
              ttl: REACT_APP_TIME_TO_LIVE,
              caseId: REACT_APP_CASE_ID,
              athleteEmail: REACT_APP_ATHLETE_EMAIL,
            }),
          });
        }
      });
    }
  }, [authService]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL?.toLowerCase()}>
      <Security authService={authService}>
        <SnackProvider>
          <Providers>
            <Suspense fallback={<div></div>}>
              <Switch>
                {/* this route is for Okta authentication purposes, */}
                <Route path='/implicit/callback/' component={LoginCallback} />
                <SecureRoute path='/' exact component={Search} />
                <SecureRoute path='/:caseId' exact component={Search} />
                <SecureRoute path='/search' component={Search} />
                <SecureRoute path='/product/gcSearch' exact component={ProductDetailsPage} />
                <SecureRoute
                  path='/product/NBY/:metricId/details'
                  exact
                  component={ProductDetailsPage}
                />
                <SecureRoute
                  path='/product/:threadId/:styleCode/details'
                  exact
                  component={ProductDetailsPage}
                />
                <SecureRoute path='/member/offers' exact component={MemberOffers} />
              </Switch>
            </Suspense>
          </Providers>
        </SnackProvider>
      </Security>
    </BrowserRouter>
  );
}

export default App;
