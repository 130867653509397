import React, { createContext, useReducer } from 'react';
import { dotComCartReducer, dotComCartInitialState } from '../reducers/dotComCartReducer';

export const DotComCartContext = createContext();
const { Provider } = DotComCartContext;

export const DotComCartProvider = ({ children }) => {
  const [state, reducer] = useReducer(dotComCartReducer, dotComCartInitialState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};

export default DotComCartContext;
