/** React / Utils */
import React, { createContext, useReducer, useEffect } from 'react';

/** Local */
import config from '../../utils/config';
import { settingsReducer } from '../reducers/settingsReducer';
import { actions } from '../actions/settingsActions';
import { getAdminToken, getOktaToken } from '../../utils/adminToken';
import { useGetUrlParams } from '../../hooks/useGetUrlParams';
import useSearchQuery from '../../hooks/useSearchQuery';

export const SettingsContext = createContext([{}, () => {}]);
export const defaultState = {};

const { Provider } = SettingsContext;

/**
 * A provider for the OMOBO settings
 *
 * @param {Object} props  – React props
 */
export const SettingsProvider = ({ children }) => {
  const [state, reducer] = useReducer(settingsReducer, defaultState);
  const caseId = useGetUrlParams('caseId');
  const { inChinaRegion } = useSearchQuery();

  const fetchAthleteSettings = async (oktaToken, snackMessage = false) => {
    if (oktaToken) {
      const response = await fetch(config.foundry.athleteSettings, {
        headers: {
          authorization: inChinaRegion() ? oktaToken?.accessToken : oktaToken,
        },
        cors: true,
      });
      if (response.ok) {
        const athleteSettings = await response.json();
        reducer(actions.setPreferences(athleteSettings));
      }
    }
  };

  useEffect(async () => {
    if (inChinaRegion()) {
      fetchAthleteSettings(await getOktaToken(caseId));
      return;
    }
    fetchAthleteSettings(await getAdminToken(caseId));
  }, []);

  const newState = { ...state };
  return <Provider value={[newState, reducer]}>{children}</Provider>;
};

export default SettingsContext;
