export const actionTypes = {
  SET_PREFERENCES: 'SET_PREFERENCES',
};

export const actions = {
  setPreferences:
    /**
     * This action sets the athlete settings
     *
     * @param {Object} groups – the groups to set in state
     */
    (preferences) => ({
      type: actionTypes.SET_PREFERENCES,
      preferences,
    }),
};

export default actions;
