import gql from 'graphql-tag';

const IDENTITY_USER_MUTATION = gql`
  mutation postReadIdentityUser(
    $id: String!
    $input: IdentityAccountUserV1RequestBody!
    $useProd: Boolean
  ) {
    identityUser(id: $id, input: $input, useProd: $useProd) {
      name {
        kana {
          given
          middle
          family
        }
        latin {
          given
          middle
          family
        }
        phonetic {
          given
          family
        }
      }
      contact {
        email {
          address
          verified
        }
        sms {
          verifiedNumber
          verifiedNumberCountry
          verificationRequired
        }
      }
      dob {
        alternativeReason
        day
        minimumAge
        month
        year
      }
      emailonly
      gender
      language
      upmId
      userType
      location {
        country
      }
    }
  }
`;

export default IDENTITY_USER_MUTATION;
