/* okta */
import { OktaAuth } from '@okta/okta-auth-js';

/* Local */
import config from './../utils/config';

const authClient = new OktaAuth(config.okta);
const tokenManager = authClient.tokenManager;
const Access_Token = 'accessToken';
const Id_Token = 'idToken';
const Max_Retry = 5;

/*
returns admin token when conditions are satisfied, retries for 5 times before
throwing an error
*/
export const getAdminToken = async (caseId, retryCount = Max_Retry) => {
  let error;
  for (let i = 0; i < retryCount; i++) {
    try {
      return await fetchAdminToken(caseId);
    } catch (err) {
      error = err;
    }
  }
  // push data to newrelic when id token is expired or not available
  const idToken = await tokenManager.get(Id_Token);
  const caseToken = await tokenManager.get(`cs_case_${caseId}`);

  if (window.newrelic) {
    window.newrelic.addPageAction('getAdminToken', {
      type: 'tokenValidation',
      caseId: caseId,
      isIdTokenExpired: idToken ? !isActive(idToken) : true,
      isCaseAccessTokenExpired: caseToken?.accessToken ? !isActive(caseToken?.accessToken) : true,
      status: 200,
    });
  }
  throw error;
};

// fetches admin token from localStorage
export const fetchAdminToken = async (caseId) => {
  /*
    if we have the case id in local storage and the token associated with it is active then
    return access token - which is an admin token
   */
  const tokenByCaseId = await tokenManager.get(`cs_case_${caseId}`);
  if (isValidCase(tokenByCaseId, caseId)) {
    if (!isActive(tokenByCaseId)) {
      /*
      removing the expired token so that we get a new token 
      when athlete start the flow again from salesforce.
      */
      /**
       * this condition is correctly reached but this error snack is never seen
       * fetchAdminToken is run in several parts of the app and reattempts if it fails
       * when a token expires this error runs first and is overwritten by the errors below
       * TODO remove log when this behavior is fixed
       */
      tokenManager.remove(`cs_case_${caseId}`);
      if (window.newrelic) {
        return window.newrelic.addPageAction('tokenExpired', {
          expiredAt: new Date(tokenByCaseId.expiresAt * 1000).toUTCString(),
        });
      }
      throw new Error(
        `Case expired at: ${new Date(
          tokenByCaseId.expiresAt * 1000
        ).toUTCString()}. Please go back and start again.`
      );
    }
    return tokenByCaseId.accessToken;
  }
  /*
    handles the below scenario:
    No case info in the nike.cs.obo.ocobo so far
  */
  const accessToken = await tokenManager.get(Access_Token);
  const idToken = await tokenManager.get(Id_Token);
  if (isValidCase(accessToken, caseId) && isActive(accessToken)) {
    tokenManager.add(`cs_case_${caseId}`, accessToken);
    return accessToken.accessToken;
  }

  /*
    handles the below scenario:
    case id in the url does not match any of the existing cases
  */
  if (accessToken) {
    const newAccessToken = await tokenManager.renew(Access_Token);
    // renewing idToken as well when renewing access token
    await tokenManager.renew(Id_Token);
    if (isValidCase(newAccessToken, caseId) && isActive(newAccessToken)) {
      tokenManager.add(`cs_case_${caseId}`, newAccessToken);
      return newAccessToken.accessToken;
    }
  } else if (!accessToken && idToken) {
    window.localStorage.removeItem('nike.cs.obo.ocobo');
  }
  // throw error in all other scenarios.
  throw new Error('Incorrect caseId or expired token. Please go back and start again.');
};

/* checks if the passed case is valid.
  case is valid if the case matches with case id in the access token
*/
const isValidCase = (token, caseId) => {
  return token && caseId === authClient.token.decode(token.accessToken)?.payload?.cs_case_caseid;
};

// checks if the passed token is active.
const isActive = (token) => {
  return !tokenManager.hasExpired(token);
};

// returns active okta token
export const getOktaToken = async () => {
  const oktaToken = await tokenManager.get(Access_Token);
  if (isActive(oktaToken)) {
    return oktaToken;
  }
  return await tokenManager.renew(Access_Token);
};
