import gql from 'graphql-tag';

const MERCH_SKU_LIST_QUERY = gql`
  query getMerchSkuList($productId: String!) {
    merchSku(productId: $productId) {
      objects {
        id
        productId
        parentType
        gtin
        nikeSize
        countrySpecifications {
          country
          localizedSize
        }
      }
    }
  }
`;

export default MERCH_SKU_LIST_QUERY;
