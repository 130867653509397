import { actionTypes } from '../actions/productDetailsActions';

export const productDetailsInitialState = {
  quantity: 1,
  selectedSku: null,
  productInfo: null,
  publishedContent: null,
  productLabel: null,
  threadId: null,
  rollupKey: null,
  relatedProducts: null,
  styleCode: null,
  isColorwaysLoading: true,
  nikeByYouProductInfo: null,
  isNikeByYou: false,
  giftCardImages: [],
  isGiftCard: false,
  showLostDemandInventoryTypes: false,
  isMemberAccessItem: false,
  isExclusiveAccessItem: false,
  giftCardRecipientDetails: null,
};

export const productDetailsReducer = (state = productDetailsInitialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SELECT_SKU:
      return {
        ...state,
        selectedSku: action.sku,
      };
    case actionTypes.UPDATE_QUANTITY:
      return {
        ...state,
        quantity: action.quantity,
      };
    case actionTypes.SET_PRODUCT_INFO:
      return {
        ...state,
        productInfo: action.productInfo,
      };
    case actionTypes.SET_PUBLISHED_CONTENT:
      return {
        ...state,
        publishedContent: action.publishedContent,
      };
    case actionTypes.SET_PRODUCT_LABEL:
      return {
        ...state,
        productLabel: action.productLabel,
      };
    case actionTypes.SET_RELATED_PRODUCTS:
      return {
        ...state,
        relatedProducts: action.relatedProducts,
      };
    case actionTypes.SET_THREAD_ID:
      return {
        ...state,
        threadId: action.threadId,
      };
    case actionTypes.SET_STYLE_CODE:
      return {
        ...state,
        styleCode: action.styleCode,
      };
    case actionTypes.CLEAR_QUANTITY_AND_SKU:
      return {
        ...state,
        quantity: 1,
        selectedSku: null,
      };
    case actionTypes.SET_COLORWAY_LOADING:
      return {
        ...state,
        isColorwaysLoading: action.isColorwaysLoading,
      };
    case actionTypes.SET_IS_NIKE_BY_YOU:
      return {
        ...state,
        isNikeByYou: action.isNikeByYou,
      };
    case actionTypes.SET_GC_IMAGES:
      return {
        ...state,
        giftCardImages: action.images,
      };
    case actionTypes.SET_IS_GIFT_CARD:
      return {
        ...state,
        isGiftCard: action.isGiftCard,
      };
    case actionTypes.SET_NBY_PRIMARY_IMAGE:
      return {
        ...state,
        productInfo: {
          ...state.productInfo,
          nbyPrimaryImage: action.nbyPrimaryImage,
        },
      };
    case actionTypes.SHOW_LOST_DEMAND_INVENTORY_TYPES:
      return {
        ...state,
        showLostDemandInventoryTypes: action.showLostDemandInventoryTypes,
      };
    case actionTypes.SET_IS_MEMBER_ACCESS_ITEM:
      return {
        ...state,
        isMemberAccessItem: action.isMemberAccessItem,
      };
    case actionTypes.SET_IS_EXCLUSIVE_ACCESS_ITEM:
      return {
        ...state,
        isExclusiveAccessItem: action.isExclusiveAccessItem,
      };
    case actionTypes.SET_ROLLUP_KEY:
      return {
        ...state,
        rollupKey: action.rollupKey,
      };
    case actionTypes.SET_GC_AMOUNT:
      return {
        ...state,
        giftAmount: action.amount,
      };
    case actionTypes.SET_VALIDATED_VAS:
      return {
        ...state,
        validatedVAS: action.vasInfo,
      };
    case actionTypes.SET_GIFT_CARD_RECIPIENT_INFO:
      return {
        ...state,
        giftCardRecipientDetails: action.giftCardRecipientDetails,
      };
    case actionTypes.SET_GC_SKU:
      return {
        ...state,
        productInfo: { skuIds: action.skuInfo, ...state.productInfo },
      };
    default: {
      return state;
    }
  }
};
