import React, { createContext, useReducer } from 'react';
import {
  productDetailsReducer,
  productDetailsInitialState,
} from '../reducers/productDetailsReducer';

export const ProductDetailsContext = createContext({});
const { Provider } = ProductDetailsContext;

export const ProductDetailsProvider = ({ children }) => {
  const [state, reducer] = useReducer(productDetailsReducer, productDetailsInitialState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};

export default ProductDetailsContext;
