import React, { createContext, useReducer } from 'react';
import { consumerReducer, consumerInitialState } from '../reducers/consumerReducer';

export const ConsumerContext = createContext({});
const { Provider } = ConsumerContext;

/**
 * A typical provider pattern for a context with a default state and a reducer
 *
 * @param {Object} props – React props
 */
export const ConsumerProvider = ({ children }) => {
  const [state, reducer] = useReducer(consumerReducer, consumerInitialState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};

export default ConsumerContext;
