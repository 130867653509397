import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';

/**
 * Wraps sidebar item for consistent styling
 *
 * @param {String} title - title of sidebar section
 * @param {fn} icon - material ui icon to display
 * @param {fn} children - inner react children
 * @param {String} dataTestId - string for datatest-id
 */
export default function SidebarItem({ title, icon, children, dataTestId }) {
  const classes = useStyles();
  return (
    <Box data-testid={dataTestId} p={2}>
      <CardHeader
        avatar={icon}
        className={classes.sidebarHeader}
        disableTypography
        classes={{
          root: classes.cardHeaderRoot,
          content: classes.cardHeaderContent,
          avatar: classes.cardHeaderAvatar,
        }}
        title={<h2 className={classes.sidebarTitle}>{title}</h2>}
      />
      {children && <Box p={1}>{children}</Box>}
    </Box>
  );
}

SidebarItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.object,
  dataTestId: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  sidebarHeader: {
    padding: '4px !important',
    color: theme.palette.grey[600],
  },
  sidebarTitle: {
    color: theme.palette.grey[600],
    fontSize: '0.875rem',
    padding: 0,
    margin: 0,
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  cardHeaderAvatar: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  cardHeaderContent: {
    alignSelf: 'flex-end',
  },
}));
