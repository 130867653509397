/** React / Utils */
import React, { createContext, useEffect, useState, useContext } from 'react';
import decodeToken from 'jwt-decode';

/** Local */
import { getAdminToken } from '../../utils/adminToken';
import { getValue } from '../../utils/sessionStorage';
import { useGetUrlParams } from '../../hooks/useGetUrlParams';
import useSearchQuery from '../../hooks/useSearchQuery';
import { SessionStorage } from './../../constants/consumerDelegatedToken.const';
import { NOBOTokenContext } from '../../store/contexts/noboTokenContext';

const defaultState = {
  caseId: '',
  upmId: '',
  userType: '',
};

export const CaseContext = createContext([defaultState, () => {}]);
const { Provider } = CaseContext;

/**
 * A read-only context for case information
 *
 * @param {Object} props  – React props
 */
export const CaseProvider = ({ children }) => {
  const [details, setDetails] = useState(defaultState);
  const caseId = useGetUrlParams('caseId');
  const { inChinaRegion } = useSearchQuery();
  const [NOBOState] = useContext(NOBOTokenContext);

  const sessionTokens = sessionStorage.getItem(SessionStorage.CaseHeader);
  const noboToken = JSON.parse(sessionTokens)?.[`cs_case_${caseId}`]?.token?.access_token;

  useEffect(async () => {
    let decodedToken;
    /**
     * Wait for getAdminToken to give us the correct token for the given case
     * decode the jwt so we can add the info to the provider
     */
    if (inChinaRegion() && noboToken) {
      decodedToken = await decodeToken(noboToken);
    } else if (!inChinaRegion()) {
      decodedToken = decodeToken(await getAdminToken(caseId));
    }

    if (decodedToken) {
      // grab the case details from the claims of the decoded token
      let tokenDetails = {};
      if (inChinaRegion()) {
        const delegatedToken = getValue(SessionStorage.CaseHeader)?.[`cs_case_${caseId}`];
        const { profileId = '', userType = '', visitorId = '' } = delegatedToken?.case || '';
        tokenDetails = {
          caseId: decodedToken.act?.cs_case_caseid || '',
          upmId: profileId,
          userType,
          visitorId,
        };
        setDetails(tokenDetails);
        return;
      }

      tokenDetails = {
        caseId: decodedToken.cs_case_caseid || '',
        upmId: decodedToken.cs_case_upmid || '',
        userType: decodedToken.cs_case_userType || '',
        visitorId: decodedToken.cs_visitor_id,
      };

      setDetails(tokenDetails);
    }
  }, [NOBOState]);

  return <Provider value={details}>{children}</Provider>;
};

export default CaseContext;
