/* React/JS */
import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/** material UI */
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

/** Local */
import { FormattedCurrency } from './formatCurrency';
import SizeDropDown from './sizeDropDown';
import MERCH_SKU_LIST_QUERY from '../../graphql/queries/merchSkuList.query';
import useCart from '../../hooks/useCart';
import DotComCartContext from '../../store/contexts/dotComCartContext';
import dotComCartActions from '../../store/actions/dotComCartActions';
import translations from './shared.i18n';
/**
 * gives an athlete the opportunity to clear or keep their cart after changing their input country
 * @param {Object} item from getCartView call item in consumer's dotcom cart
 * @param {Function} selectItem handler for item selection
 * @param {Function} setItemSizeSelections allows alternative size selection for given cart item
 * @param {String} currency from getCartView call. used to format currency
 * @returns a react component that goes in a cart
 */
const CartItem = memo(
  ({ item, selectItem, selectItemSize, currency, country, selectable = false }) => {
    const [, dotComCartDispatch] = useContext(DotComCartContext);
    const { setSizeSkuIds } = dotComCartActions;
    const { setDotComCartModalError } = useCart({});
    const classes = useStyles();

    const { i18nString } = useContext(NikeI18nContext);
    const { NO_SIZE_DATA_FOR_CART_ITEM } = mapValues(translations, i18nString);

    const {
      id,
      contentDetails,
      selectedSku,
      productDetails,
      productId,
      itemCosts,
      imagesDetails,
    } = item;

    const { styleColor } = productDetails || {};
    const { title, subtitle, colorDescription } = contentDetails || {};
    const imageSource = imagesDetails.images[0];

    const { loading: sizesLoading } = useQuery(MERCH_SKU_LIST_QUERY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        productId: productId,
      },
      onCompleted: (data) => {
        const sizeData = {};
        data.merchSku.objects?.forEach((sku) => {
          sizeData[sku.gtin] = {
            skuId: sku.id,
            localizedSize:
              sku.countrySpecifications.find((spec) => spec.country === country)?.localizedSize ||
              sku.nikeSize,
          };
        });

        if (Object.keys(sizeData).length) {
          dotComCartDispatch(setSizeSkuIds(id, sizeData));
        } else {
          dotComCartDispatch(setDotComCartModalError(`${NO_SIZE_DATA_FOR_CART_ITEM}: ${id}`));
        }
      },
    });

    return (
      <ListItem data-testid={`consumer-item-${id}`} key={id}>
        {selectable && (
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                checked={item.isSelected}
                edge='start'
                tabIndex={-1}
                color='primary'
                data-testid={`consumer-item-${id}-checkbox`}
                onClick={() => selectItem(id)}
                inputProps={{ 'aria-label': `Select ${title}` }}
              />
            }></FormControlLabel>
        )}
        <ListItemText>
          <Box className={classes.item}>
            {imageSource && (
              <Box
                style={{ backgroundImage: `url(${imageSource})` }}
                role='img'
                title={title}
                alt={title}
                className={classes.imgContainer}></Box>
            )}
            <Box className={classes.itemInfo}>
              <Box className={classes.flexSpaceBetween}>
                <Typography variant='h5' component='h3'>
                  {title}
                </Typography>
                <Typography variant='h5' component='h3'>
                  <FormattedCurrency amount={itemCosts?.priceInfo?.total} currency={currency} />
                </Typography>
              </Box>
              <Box className={classes.block}>
                <Typography variant='body1'>{styleColor}</Typography>
                <Typography variant='body1'>{subtitle}</Typography>
                <Typography variant='body1'>{colorDescription}</Typography>
              </Box>
              {sizesLoading ? (
                <CircularProgress />
              ) : (
                <SizeDropDown
                  handleSizeSelect={selectItemSize}
                  itemId={id}
                  selectedId={selectedSku}
                  sizeSpecs={item.alternativeSizes}
                />
              )}
            </Box>
          </Box>
        </ListItemText>
      </ListItem>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    width: '184px',
    height: '184px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  item: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(0.5),
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemInfo: {
    flex: 1,
    padding: theme.spacing(0, 0, 0, 3),
  },
  block: {
    margin: theme.spacing(2, 0),
  },
  block1: {
    flex: 1,
    padding: theme.spacing(0, 0, 0, 25),
  },
}));

CartItem.propTypes = {
  item: PropTypes.shape({
    contentDetails: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      colorDescription: PropTypes.string,
    }),
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    imagesDetails: PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
    }),
    itemCosts: PropTypes.shape({
      priceInfo: PropTypes.shape({
        total: PropTypes.number,
      }),
    }),
    productDetails: PropTypes.shape({
      styleColor: PropTypes.string,
    }),
    productId: PropTypes.string,
    skuId: PropTypes.string,
  }).isRequired,
  selectItem: PropTypes.func,
  selectItemSize: PropTypes.func,
  currency: PropTypes.string,
  selectable: PropTypes.bool,
};

CartItem.defaultProps = {
  selectable: false,
};

export default CartItem;
