/** React / Utils */
import React, { createContext, useState, useEffect } from 'react';

/** Local */
import config from '../../utils/config';
import { logErrorAction } from '../../newrelic/logErrorAction';
import { getAdminToken, getOktaToken } from '../../utils/adminToken';
import { useGetUrlParams } from '../../hooks/useGetUrlParams';
import useSearchQuery from '../../hooks/useSearchQuery';

export const PermissionContext = createContext([{}, () => {}]);
const { Provider } = PermissionContext;

/**
 * A basic provider for a context with only a single value
 *
 * @param {Object} props  – React props
 */
export const PermissionProvider = ({ children }) => {
  const [state, setState] = useState([]);
  const caseId = useGetUrlParams('caseId');
  const { inChinaRegion } = useSearchQuery();

  const fetchPermissions = async (oktaToken) => {
    if (oktaToken) {
      const response = await fetch(config.foundry.activePermissions, {
        headers: {
          authorization: inChinaRegion() ? oktaToken?.accessToken : oktaToken,
        },
        cors: true,
      });
      try {
        // todo handle this in the back
        const usersActivePermissions = await response.json();
        const lowerCasePermissions =
          (Array.isArray(usersActivePermissions) &&
            usersActivePermissions.map((usersActivePermission) => {
              return {
                region: usersActivePermission.region,
                permissions: usersActivePermission.permissions.map((permission) =>
                  permission.toLowerCase()
                ),
              };
            })) ||
          [];
        setState(lowerCasePermissions);
      } catch (e) {
        logErrorAction(e, { customMessage: 'fetchPermissions, permissionContext.js' });
      }
    }
  };

  useEffect(async () => {
    if (inChinaRegion()) {
      fetchPermissions(await getOktaToken());
      return;
    }
    fetchPermissions(await getAdminToken(caseId));
  }, []);

  return <Provider value={[state]}>{children}</Provider>;
};

export default PermissionContext;
