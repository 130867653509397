import { createMuiTheme, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import NikeFutura from './assets/fonts/futura.ttf';

const futuraFont = {
  fontFamily: 'Nike Futura',
  fontStyle: 'semi-bold',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: `
   url(${NikeFutura}) format('ttf')
 `,
};

const themeConfig = {
  typography: {
    fontFamily: ['"Nike Futura"', 'Helvetica Neue', 'Helvetica'].join(','),
    h1: {
      fontFamily: '"Nike Futura", Futura',
    },
    h2: {
      fontFamily: '"Nike Futura", Futura',
    },
    h3: {
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: '500',
    },
    h4: {
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: '500',
    },
    body: {
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: '500',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [futuraFont],
      },
    },
  },
  spacing: 8,
  palette: {
    common: {
      black: 'rgba(0, 0, 0, 0.87)',
      white: '#fff',
      purple: '#252E6E',
    },
    type: 'light',
    nike: {
      orange: '#CC4400',
    },
    primary: {
      light: '#7986cb',
      main: '#252E6E',
      dark: '#303f9f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#111',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
  },
};
// using this theme in test prevents findDOMNode errors
const theme =
  process.env.REACT_APP_ENV === 'dev'
    ? unstable_createMuiStrictModeTheme(themeConfig)
    : createMuiTheme(themeConfig);

export default theme;
